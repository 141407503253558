import { Grid } from "@mui/material";
import { format } from "date-fns";
import moment from "moment";
import React, { Fragment } from "react";
import { EventSectionCard } from "../Global/EventSectionCard";
import Pagination from "../Global/Pagination";

export const EventCard = () => {
  const [events, setEvents] = React.useState([]);

  var eventUrl = `${process.env.REACT_APP_BASEURL_API}/event/all`;

  const getEvent = async () => {
    const pageURL = eventUrl;
    try {
      const response = await fetch(pageURL, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        // Always write this in order not to have CORS issues
        withCredentials: false,
        crossdomain: false,
        // mode: 'no-cors',
      });
      const jsonData = await response.json();
      setEvents(jsonData);
      // console.log(toolkits);
    } catch (err) {
      console.error(err.message);
    }
  };

  const [currentPage, setCurrentPage] = React.useState(1);
  const [postsPerPage, setPostsPerPage] = React.useState(3);

  const lastPostIndex = currentPage * postsPerPage;
  const firstPostIndex = lastPostIndex - postsPerPage;
  const currentPosts = events.slice(firstPostIndex, lastPostIndex);

  React.useEffect(() => {
    getEvent();
  }, []);

  const [noOfElement, setnoOfElement] = React.useState(3);
  const sliceD = events.slice(0, noOfElement);
  return (
    <Fragment>
      <Grid container spacing={3} className="px-5 md:px-4 lg:px-20 py-10">
        {currentPosts.map((event, index) => (
          <Grid item={true} xs={12} sm={12} md={12} lg={12} key={index}>
            <EventSectionCard
              date={format(new Date(event.eventdate), "do MMM, yyyy")}
              time={moment.utc(event.eventdate).local().format("h:mm a")}
              title={event.title}
              text={event.body}
              path={`/events/view/${event.slug}`}
              image={event.imgSrc}
            />
          </Grid>
        ))}
      </Grid>

      <div className=" mx-auto">
        <Pagination
          totalPosts={events.length}
          postsPerPage={postsPerPage}
          setCurrentPage={setCurrentPage}
          currentPage={currentPage}
        />
      </div>
    </Fragment>
  );
};

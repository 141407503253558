import React from "react";

export const Map = () => {
  return (
    <div className="w-screen">
      <iframe
        height="400"
        frameBorder="2"
        scrolling="no"
        marginHeight="0"
        className="w-full  drop-shadow-lg"
        id="gmap_canvas"
        src="https://maps.google.com/maps?q=ccc%20salvation%20parish&t=&z=13&ie=UTF8&iwloc=&output=embed"
      ></iframe>
    </div>
  );
};

import { LibraryBooks } from "@mui/icons-material";
import React, { Fragment } from "react";
import { JustificationCard } from "../../components/About/JustificationCard";
import { MiniHeader } from "../../components/Global/MiniHeader";
import { BackToTop } from "../../templates/BackToTop";

export const BibleJustification = () => {
  return (
    <Fragment>
      <div className="bg-gray-100">
        <MiniHeader
          miniTitle={<LibraryBooks />}
          title="Biblical Justification Of CCC Doctrines"
          text="“The Bible says ye shall know the truth and the truth shall make you free” John 8:32"
        />
      </div>
      <JustificationCard />
      <BackToTop />
    </Fragment>
  );
};

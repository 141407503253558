import { Send } from "@mui/icons-material";
import React, { Fragment } from "react";
import { useNavigate } from "react-router-dom";
import { CustomMiniBtn } from "../../templates/CustomMiniBtn";

export const ResCard = (props) => {
  let navigate = useNavigate();
  return (
    <Fragment>
      <div className="tk-card shadow-xl dark:text-primary relative">
        <img
          src={props.image}
          alt={props.title}
          className="w-full md:h-56 h-44 object-cover"
        />

        <div className="m-4">
          <div className="font-bold text-secondary uppercase text-sm md:text-base lg:text-xl font-aref line-clamp-1">
            {props.title}
          </div>

          <div className="flex items-center pb-1">
            <cite className="pr-3 font-medium text-primary font-abel">
              {props.icon}
            </cite>
            <p className="pr-3 font-medium text-gray-500 font-abel">
              {props.iconText}
            </p>
            <p className={props.comHide}>| {props.comment} comments</p>
          </div>
          <div className=" text-primary font-abel text-base line-clamp-2">
            {props.phrase}
          </div>

          <div className="mt-2 absolute bottom-5">
            <CustomMiniBtn
              name={props.btnName}
              icon={<Send className="ml-2" />}
              onClick={() => {
                navigate(props.path);
                window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
              }}
            />
          </div>
        </div>
        <div className="tk-badge">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-4 h-4 -mt-1 inline-block items-center"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5m-9-6h.008v.008H12v-.008zM12 15h.008v.008H12V15zm0 2.25h.008v.008H12v-.008zM9.75 15h.008v.008H9.75V15zm0 2.25h.008v.008H9.75v-.008zM7.5 15h.008v.008H7.5V15zm0 2.25h.008v.008H7.5v-.008zm6.75-4.5h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V15zm0 2.25h.008v.008h-.008v-.008zm2.25-4.5h.008v.008H16.5v-.008zm0 2.25h.008v.008H16.5V15z"
            />
          </svg>

          <i className="text-sm font-abel">{props.date}</i>
        </div>
      </div>
    </Fragment>
  );
};

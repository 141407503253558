import { Church, TodayOutlined } from "@mui/icons-material";
import { Box, Container } from "@mui/material";
import React, { Fragment, useState } from "react";
import Slider from "react-slick";
import { serviceData } from "../../utils/WelcomeData";
import { MiniHeader } from "../Global/MiniHeader";

export const Services = () => {
  const [slideIndex, setSlideIndex] = useState(0);

  const settings = {
    centerMode: true,
    centerPadding: "0px",
    dots: false,
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 2000,
    beforeChange: (current, next) => setSlideIndex(next),
    // nextArrow: <SampleNextArrow />,
    // prevArrow: <SamplePrevArrow />,
  };
  const settingsMob = {
    centerMode: true,
    centerPadding: "0px",
    dots: false,
    infinite: true,
    slidesToShow: 2,
    slidesToScroll: 1,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 2000,
    beforeChange: (current, next) => setSlideIndex(next),
    // nextArrow: <SampleNextArrow />,
    // prevArrow: <SamplePrevArrow />,
  };

  return (
    <Fragment>
      <div className="bg-gray-100 pb-6">
        <MiniHeader
          miniTitle={<Church />}
          title="Church Services"
          text="Our weekly services commence as listed below"
        />

        <Container className="mt-6 md:mt-12 !w-screen">
          <div className="slider hidden md:block">
            <Slider {...settings}>
              {serviceData.map((service, index) => (
                <div className="!text-center " key={index}>
                  <TodayOutlined className=" text-custom !text-4xl md:!text-6xl" />
                  <p className="text-xl md:text-2xl font-aref font-bold text-secondary">
                    {service.title}
                  </p>
                  <p className="text-base md:text-xl text-primary pt-4 font-abel">
                    {service.text} <br />
                    <p className="text-xs md:text-sm opacity-70">
                      {service.time}
                    </p>
                  </p>
                </div>
              ))}
            </Slider>
          </div>
          <div className="slider block md:hidden">
            <Slider {...settingsMob}>
              {serviceData.map((service, index) => (
                <div className="!text-center" key={index}>
                  <TodayOutlined className=" text-custom !text-4xl md:!text-6xl" />
                  <div className="text-xl md:text-2xl font-aref font-bold text-secondary">
                    {service.title}
                  </div>
                  <div className="text-base md:text-xl text-primary pt-4 font-abel">
                    {service.text} <br />{" "}
                    <div className="text-xs md:text-sm opacity-70">
                      {service.time}
                    </div>
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        </Container>
      </div>
    </Fragment>
  );
};

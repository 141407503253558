import { ArrowUpward } from "@mui/icons-material";
import React, { useState, useEffect } from "react";

export const BackToTop = () => {
  const [showTopBtn, setShowTopBtn] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 0) {
        setShowTopBtn(true);
      } else {
        setShowTopBtn(false);
      }
    });
  }, []);

  const goToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div>
      {showTopBtn && (
        <button
          onClick={goToTop}
          title="Go To Top"
          className="fixed z-90 bottom-8 left-2 lg:left-2 bg-custom md:w-14 md:h-14 h-12 w-12 rounded-full drop-shadow-xl shadow-xl flex justify-center items-center text-secondary text-base md:text-2xl hover:text-white hover:drop-shadow-2xl hover:shadow-2xl"
        >
          <ArrowUpward />
        </button>
      )}
    </div>
  );
};

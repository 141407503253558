import { Festival } from "@mui/icons-material";
import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { EventCard } from "../components/Events/EventCard";
import { MiniHeader } from "../components/Global/MiniHeader";
import { BackToTop } from "../templates/BackToTop";

export const Events = () => {
  return (
    <Fragment>
      <div className="bg-gray-100 py-8">
        <MiniHeader
          miniTitle={<Festival />}
          title="Programs & Events"
          text="Catch up on amazing events"
        />
        <div className=" text-center">
          <Link
            to={"/events/yearly"}
            onClick={() => {
              window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
            }}
            className="text-xl font-aref text-secondary underline hover:text-custom"
          >
            Yearly Programs
          </Link>
        </div>
      </div>
      <EventCard />
      <BackToTop />
    </Fragment>
  );
};

import React from "react";
import { Button } from "@mui/material";

export const CustomButton = (props) => {
  return (
    <Button
      variant="contained"
      color="custom"
      className="btn2 mt-12 h-12 relative border !text-2xl  !rounded-full  border-white uppercase font-abel  tracking-wider leading-none overflow-hidden hover:text-teal-600"
      onClick={props.onClick}
    >
      <span className="absolute inset-0 bg-secondary "></span>
      <span className="absolute inset-0 flex justify-center items-center ">
        {props.name}
        {props.icon}
      </span>
      {props.name}
      {props.icon}
    </Button>
  );
};

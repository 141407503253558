import * as React from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Divider from "@mui/material/Divider";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";

export const Commenter = (props) => {
  return (
    <List
      sx={{ width: "100%", maxWidth: "100%", bgcolor: "background.paper" }}
      className=""
    >
      <ListItem alignItems="flex-start">
        <ListItemAvatar>
          <Avatar alt={props.name} src="/static/image.png" />
        </ListItemAvatar>
        <ListItemText
          primary=""
          secondary={
            <React.Fragment>
              <div className="text-lg font-bold font-aref text-secondary">
                {props.name}
              </div>
              <div className="flex ">
                <Typography
                  sx={{ display: "inline" }}
                  component="span"
                  variant="body2"
                  color="text.primary"
                >
                  {props.email} {" — "}
                </Typography>
                <p
                  style={{ textAlign: "left" }}
                  className="italic text-secondary"
                >
                  posted {props.time}
                </p>
              </div>
              <p className="text-primary">{props.comment}</p>
            </React.Fragment>
          }
        />
      </ListItem>
    </List>
  );
};

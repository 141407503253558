import React, { Fragment } from "react";
import { List, ListItem, ListItemIcon, Typography } from "@mui/material";
import { justifyItems } from "../../utils/JustifyData";

export const JustificationCard = () => {
  return (
    <Fragment>
      <div className="px-2 py-4 md:px-6 md:py-16 lg:px-20 lg:py-20">
        <img
          src="https://res.cloudinary.com/dsywsrg4f/image/upload/v1664984357/salvation/romans_iq12op.jpg"
          alt="Biblical Justification Of CCC Doctrines"
          className="w-full h-72 md:h-[600px] object-cover "
        />
      </div>

      <div className="overflow-x-auto relative shadow-xl sm:rounded-lg px-2 md:px-6 lg:px-20 mx-auto mb-12">
        <table className="w-full text-sm text-left px-12">
          <thead className="text-xs  uppercase bg-custom  ">
            <tr>
              <th
                scope="col"
                className="py-3 px-6 text-secondary font-aref md:text-2xl text-xl"
              >
                Doctrine
              </th>
              <th
                scope="col"
                className="py-3 px-6 font-aref text-secondary md:text-2xl text-xl"
              >
                Bible Justification
              </th>
            </tr>
          </thead>
          {justifyItems.map((event, index) => (
            <tbody>
              <tr className="bg-white border-b border-2 text-primary ">
                <th
                  scope="row"
                  className="py-4 px-6 capitalize font-bold text-secondary whitespace-nowrap font-abel text-xl"
                  key={index}
                >
                  {event.doct}
                </th>
                <td className="py-4 px-6 font-abel text-lg capitalize">
                  {event.bible}
                </td>
              </tr>
            </tbody>
          ))}
        </table>
      </div>
    </Fragment>
  );
};

import { Container } from "@mui/material";
import React, { Fragment } from "react";

export const MiniHeader = (props) => {
  return (
    <Fragment>
      <Container className="text-center !mx-auto py-12">
        <div className="flex items-center py-4 md:px-52 lg:px-[450px] px-12 overflow-x-hidden overscroll-x-none">
          <div className="flex-grow h-px bg-gray-400"></div>

          <span className="flex-shrink uppercase tracking-[8px] text-[14px] text-gray-500 px-4 italic font-light">
            {props.miniTitle}
          </span>

          <div className="flex-grow h-px bg-gray-400"></div>
        </div>
        <p className="text-center text-2xl md:text-4xl font-aref font-bold text-primary">
          {props.title}
        </p>
        <p className="text-center text-sm md:text-lg font-abel text-primary opacity-75 pt-2 md:pt-4">
          {props.text}
        </p>
      </Container>
    </Fragment>
  );
};

export const justifyItems = [
  {
    doct: "Wearing white garment - Sutana",
    bible:
      "Rev 7:9, Rev 4:4,Lev 16:14, Rev 19:8, Matt. 17:1-2, Matt. 28:2-3, Mark 9:2-3,Daniel 7:9,John 20:11-12,",
  },
  {
    doct: "Wearing no shoes",
    bible: "Acts 7:33, Joshua 5:15, Exodus 3:5",
  },
  {
    doct: "Lighting candles in the altar",
    bible: "Rev 4:5; Zech 4:2-5",
  },
  {
    doct: "Bowing down before the Altar",
    bible: "Rev 4:3-10, 14:7",
  },
  {
    doct: "Chanting Holy, Holy, Holy to the Lord of Hosts",
    bible: "Rev 4:8",
  },
  {
    doct: "The eye on the Celestial logo",
    bible: "Rev 5:6",
  },
  {
    doct: "Using incense during services",
    bible:
      "Rev 5:8 & 8:3-5; Exodus 30:8-9; Luke 1:9-11; Exodus 30:24-38; Exodus 40:5; Exodus 31:10-11; Malachi 1:11",
  },
  {
    doct: "The salvation of C.C.C.",
    bible: "Rev 7:14; Romans 8:28-30",
  },
  {
    doct: "Using of the Holy water",
    bible: "Exodus 13:17-21; Number 8:6-7; Exodus 27:20",
  },
  {
    doct: "Keeping the Altar light burning regularly",
    bible: "Lev. 24:4-6; Exodus 27:20-21",
  },
  {
    doct: "Making announcements during services	",
    bible: "Exodus 29:7",
  },
  {
    doct: "Uses of Church oil",
    bible: "Exodus 29:36; James 5:13-15; Mark 6:13; Number 10:1",
  },
  {
    doct: "Stream bath",
    bible: "2 Kings 5:10-15; John 9:6-11",
  },
  {
    doct: "Conducting New Moon services",
    bible: "Number 28:9-15; Isaiah 1:13-14; Numbers 10:1",
  },
  {
    doct: "Washing of feet",
    bible: "John 3:5, 14 & 15; II Kings 5:10-15; John 9:6-11",
  },
  {
    doct: "Use of palm front",
    bible: "Rev 7:9; John 12:12-18",
  },
  {
    doct: "Not allowing corpses in the sanctuary",
    bible: "Ezekiel 43:7-9",
  },
  {
    doct: "Using salt in thanks offering",
    bible: "Lev. 2:13; Matthew 5:18",
  },
  {
    doct: "No alcohol",
    bible: "Leviticus 10:8-11; Genesis 9:2-25",
  },
  {
    doct: "Mandatory purification for women after menstrual period",
    bible: "Lev 12:2-3 & 15:4-9",
  },
  {
    doct: "Use of Perfume",
    bible: "Matthew 26: 6-13",
  },
  {
    doct: "The seven candles = the 7 spirits of God",
    bible:
      "Rev 4:1-5, Rev 1:12&20, Zechariah 4:2, Exodus 25:31-37, Exodus 37:23, Number 8:2",
  },
  {
    doct: "Purification for service conductor",
    bible: "Lev. 10:3, 6:9; Exodus 10:15",
  },
  {
    doct: "Need for thanks offering",
    bible: "Exodus 34:20",
  },
  {
    doct: "The church as meeting place between God & His believers",
    bible: "Exodus 29:12-45; Hymn 5",
  },
  {
    doct: "Celestial Church and their fellow men",
    bible: "Roman 12:17-19; Hebrew 12:14",
  },
  {
    doct: "Holy Michael - Head of Angels of God",
    bible: "Rev 12:7-19; Daniel 10:21",
  },
  {
    doct: "Annual Juvenile Harvest",
    bible: "Mark 10:13-16; Matthew 19:13-15",
  },
  {
    doct: "Shouting of Halleluyah",
    bible: "Rev 19:1-7",
  },
  {
    doct: "Naming Ceremony",
    bible: "Lev. 12:1-8",
  },
  {
    doct: "Loin and Girdle",
    bible:
      "Daniel 10:5, Rev. 1:13, Exodus 29:5, Matthew 3:4, Acts 21:11, Psalm 109:19, Lev. 16:4",
  },
  {
    doct: "Meaning of JHS",
    bible:
      "1. Jesus Homini Saviotor 2. Jesus Heals & Saves  3. Jesus, Saviour of Humanity",
  },
];

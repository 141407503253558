import { LibraryBooks } from "@mui/icons-material";
import React, { Fragment } from "react";
import { CCCHistoryCard } from "../../components/About/CCCHistoryCard";
import { MiniHeader } from "../../components/Global/MiniHeader";
import { BackToTop } from "../../templates/BackToTop";

export const CCChistory = () => {
  return (
    <Fragment>
      <div className="bg-gray-100 py-8">
        <MiniHeader
          miniTitle={<LibraryBooks />}
          title="CCC History"
          text="Dive right in and learn about this great church"
        />
      </div>
      <CCCHistoryCard />
      <BackToTop />
    </Fragment>
  );
};

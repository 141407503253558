import React, { Fragment } from "react";

const Pagination = ({
  totalPosts,
  postsPerPage,
  setCurrentPage,
  currentPage,
}) => {
  let pages = [];

  for (let i = 1; i <= Math.ceil(totalPosts / postsPerPage); i++) {
    pages.push(i);
  }

  return (
    <Fragment>
      <div className="flex mx-auto place-content-center !h-auto py-6 px-6">
        {currentPage === 1 ? (
          <div className="mr-auto">
            <a
              aria-disabled="true"
              className=" flex hover:text-gray-300 hover:no-underline text-gray-300 text-base"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth={2}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M15 19l-7-7 7-7"
                />
              </svg>
              Previous
            </a>
          </div>
        ) : (
          <div className="mr-auto">
            <a
              className="text-gray-700 flex text-base hover:no-underline cursor-pointer"
              onClick={() => {
                setCurrentPage(currentPage - 1);
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth={2}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M15 19l-7-7 7-7"
                />
              </svg>{" "}
              Previous
            </a>
          </div>
        )}

        {/* <div className="pagination">
          {pages.map((page, index) => {
            return (
              <>
                <button
                  key={index}
                  onClick={() => setCurrentPage(page)}
                  className={
                    page === currentPage
                      ? "font-bold text-mhblack font-luxurious bg-mhyellow px-2 rounded-md text-base mx-2"
                      : "mx-2"
                  }
                >
                  {page}
                </button>
              </>
            );
          })}
        </div> */}

        {currentPage >= pages.length ? (
          <div className="ml-auto">
            <a
              aria-disabled="true"
              className=" flex hover:text-gray-300 hover:no-underline text-gray-300 text-base"
            >
              Next{" "}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth={2}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M9 5l7 7-7 7"
                />
              </svg>
            </a>
          </div>
        ) : (
          <div className="ml-auto">
            <a
              className="text-gray-700 flex hover:no-underline text-base cursor-pointer"
              onClick={() => {
                setCurrentPage(currentPage + 1);
              }}
            >
              Next
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth={2}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M9 5l7 7-7 7"
                />
              </svg>
            </a>
          </div>
        )}
      </div>
    </Fragment>
  );
};

export default Pagination;

import { Button } from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { navItems } from "../../utils/NavData";

export const Header = () => {
  const [toggleopen, setToggleOpen] = useState(true);
  const location = useLocation();
  const [active, setActive] = useState(location.pathname);

  // console.log(location.pathname);

  useEffect(() => {
    setActive(location.pathname);
  }, []);

  return (
    <Fragment>
      <nav className="bg-white sticky top-0 z-50 border-gray-200 h-20 lg:h-20 md:py-0 px-2 sm:px-4 shadow-lg rounded font-abel">
        <div className="container flex flex-wrap justify-between items-center md:px-0 lg:px-20">
          <a
            href="/"
            className="flex items-center py-4 md:py-0 md:-mt-5"
            onClick={() => {
              setActive(location.pathname);
            }}
          >
            <img
              src="salv.png"
              className="md:-mr-20 lg:mr-0 h-12 w-20 md:h-12 md:w-20 lg:h-15 lg:w-24"
              alt="MH Logo"
            />
            <div className="self-center font-aref ml-2 md:ml-20 lg:ml-2 text-xl lg:text-2xl font-bold whitespace-nowrap text-secondary">
              Salvation Parish
            </div>
          </a>

          <button
            onClick={() => setToggleOpen(!toggleopen)}
            id="hiders"
            data-collapse-toggle="mobile-menu"
            type="button"
            className="cursor-pointer inline-flex items-center p-2 ml-3 text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 "
            aria-controls="mobile-menu"
            aria-expanded="false"
          >
            <span className="sr-only">MH Menu</span>
            <svg
              className="w-6 h-6"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                clipRule="evenodd"
              ></path>
            </svg>
            <svg
              className="hidden w-6 h-6"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clipRule="evenodd"
              ></path>
            </svg>
          </button>

          <div
            className={`${
              toggleopen ? "hidden" : "block"
            } w-full md:block md:w-auto md:-mt-4 scroll-smooth " id="menu-mob`}
          >
            <ul
              id="navdiv"
              className="absolute bg-white md:bg-transparent w-full md:w-fit left-0 md:relative flex flex-col md:py-12 md:flex-row uppercase md:space-x-4 lg:space-x-8 md:text-sm md:font-medium items-center  md:-ml-20 md:mt-0 lg:mt-0"
            >
              {navItems.map((item, index) => (
                <li key={index}>
                  <Link
                    onClick={() => {
                      setActive(item.path);
                      setToggleOpen(true);
                      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
                    }}
                    to={item.path}
                    className={`${
                      active === item.path ? "text-secondary " : " text-primary"
                    } hover-underline-animation  text-base py-2 pr-4 pl-3  border-b border-gray-100 hover:bg-gray-50 md:hover:bg-transparent bgactive md:border-0 md:hover:text-mhash md:p-0 `}
                    aria-current="page"
                  >
                    {item.name}
                  </Link>
                </li>
              ))}

              <li className="-mt-2 pt-2 pb-2 md:pb-0 md:pt-0">
                <Button
                  variant="contained"
                  color="custom"
                  className="btn2 mt-12 h-8 lg:h-10 relative border md:text-lg lg:!text-xl  !rounded-full  border-white uppercase font-abel font-semibold tracking-wider leading-none overflow-hidden hover:text-teal-600"
                  onClick={() => {
                    window.open("https://paystack.com/pay/salvation-donation");
                    setToggleOpen(true);
                  }}
                >
                  <span className="absolute inset-0 bg-secondary"></span>
                  <span className="absolute inset-0 flex justify-center items-center ">
                    Donations
                  </span>
                  Donations
                </Button>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </Fragment>
  );
};

export const carouselImages = [
  {
    id: 1,
    img: "https://res.cloudinary.com/dsywsrg4f/image/upload/v1664669306/salvation/event_rw9ljl.jpg",
    title: "",
    quote: "",
    label: "Worship With Us",
    path: "/events",
  },
  {
    id: 2,
    img: "https://res.cloudinary.com/dsywsrg4f/image/upload/v1664667779/salvation/bible_ndaure.jpg",
    title: "ARE YOU A BELIEVER?",
    quote:
      "But be ye doers of the word, and not hearers only, deceiving your own selves. James 1:22 (KJV)",
    label: "Read More",
    path: "/articles",
  },
  {
    id: 3,
    img: "https://res.cloudinary.com/dsywsrg4f/image/upload/v1664669530/salvation/donation_kbibjs.jpg",
    title: "Sowing Good Seeds",
    quote:
      "He that soweth the good seed is the Son of man.  Matthew 13:37 (KJV)",
    label: "Read More",
    path: "/articles",
  },
];

import React, { Fragment } from "react";
import { List, ListItem, ListItemIcon, Typography } from "@mui/material";

export const TenetsCard = () => {
  return (
    <Fragment>
      <div className="px-2 py-4 md:px-6 md:py-16 lg:px-20 lg:py-20">
        <img
          src="https://res.cloudinary.com/dsywsrg4f/image/upload/v1664879095/salvation/rules_lbjqwa.jpg"
          alt="CCC Rules & Tenets"
          className="w-full h-72 md:h-[600px] object-cover "
        />

        <div className="py-10">
          <div className="eventBody font-abel text-lg">
            <p className="pt-8">
              <p className="text-secondary pb-4 font-aref font-bold text-2xl md:text-3xl">
                Code of Conduct
              </p>
              The following is a summary of rules, regulations and tenets of
              Celestial Church of Christ.
            </p>
            <p className="pt-8">
              <p className="text-secondary pb-4 font-aref font-bold text-2xl md:text-3xl">
                Members are forbidden to:
              </p>
              <List className="">
                <ListItem component="div">
                  <ListItemIcon>1</ListItemIcon> Commit Adultery or Fornication
                </ListItem>
                <ListItem component="div">
                  <ListItemIcon className="">2</ListItemIcon> Drink Alcohol,
                  Wine and Smoke Cigarettes
                </ListItem>
                <ListItem component="div">
                  <ListItemIcon className="">3</ListItemIcon> Eat Pork &
                  Crawling Animals
                </ListItem>
                <ListItem component="div">
                  <ListItemIcon className="">4</ListItemIcon> Merry-go-round at
                  night
                </ListItem>
                <ListItem component="div">
                  <ListItemIcon className="">5</ListItemIcon> Wear transparent
                  dress, especially for Soutana while in the Church premises
                </ListItem>
                <ListItem component="div">
                  <ListItemIcon className="">6</ListItemIcon> Wear Red or Black
                  apparel, except for professional reasons
                </ListItem>
                <ListItem component="div">
                  <ListItemIcon className="">7</ListItemIcon> Sit male & female
                  members side by side inside the Church
                </ListItem>
                <ListItem component="div">
                  <ListItemIcon className="">8</ListItemIcon> Female members are
                  forbidden to leave their heads uncovered
                </ListItem>
                <ListItem component="div">
                  <ListItemIcon className="">9</ListItemIcon> Female members are
                  forbidden to paint their lips and nails
                </ListItem>
                <ListItem component="div">
                  <ListItemIcon className="">10</ListItemIcon> Male members are
                  forbidden to wear their hair too long
                </ListItem>
                <ListItem component="div">
                  <ListItemIcon className="">11</ListItemIcon> Women NEED to be
                  Sanctified by an elder in the Church after 7th day of their
                  monthly cycle
                </ListItem>
              </List>
            </p>
            <p className="pt-8">
              <p className="text-secondary pb-4 font-aref font-bold text-2xl md:text-3xl">
                Spiritual Code of Conduct
              </p>
              <List className="">
                <ListItem component="div">
                  <ListItemIcon>1</ListItemIcon> Upon entering the church, at
                  all times, but especially during church services, an
                  individual should remember that he or she is in the House of
                  God. (1 Tim. 3:15). Reverence and good manners are required so
                  as not to disturb those who are already engaged in prayer and
                  worship, but even more importantly, as an expression of
                  sincere fear, faith, and awareness of the presence of the
                  Sovereign God of Celestial and the Universe.
                </ListItem>
                <ListItem component="div">
                  <ListItemIcon className="">2</ListItemIcon> You must be
                  properly and appropriately dressed without any loose buttons
                  before coming into the Sanctuary, otherwise, the warden will
                  politely ask that you do it right.
                </ListItem>
                <ListItem component="div">
                  <ListItemIcon className="">3</ListItemIcon> All must not wear
                  transparent or tight regalia (Sutana) to the church. It is
                  ungodly. Therefore, appropriate and respectful clothing should
                  be worn at all times. We do this not to impress or spiritually
                  denial each other but to show our Lord that we offer our best
                  to mirror the saints; for He deserves only our reverence,
                  fear, and holiness. Psalm 96:9; 1 Peter 1: 15-17.
                </ListItem>
                <ListItem component="div">
                  <ListItemIcon className="">4</ListItemIcon>
                  No Male not yet anointed is allowed into the altar.
                  <br />
                  No female shall under any circumstance go beyond the threshold
                  close to the altar and no female shall be allowed to touch the
                  sacred items such as sanctification water vessel, the
                  in-censer or the Cross in the altar.
                </ListItem>
                <ListItem component="div">
                  <ListItemIcon className="">5</ListItemIcon>Women who are still
                  in their flow must of necessity go through sanctification on
                  the eighth day of the commencement of their cycle. If the flow
                  exceeds seven days, then they must add additional one day
                  after the end the flow before they can come to the church for
                  sanctification. Please keep off the environment of the church
                  for clear seven days.
                </ListItem>
                <ListItem component="div">
                  <ListItemIcon className="">6</ListItemIcon> Children should be
                  kept effectively under control by their parents during church
                  services. No child shall be allowed to run up and down the
                  sanctuary of God. Such children will be taken out and
                  controlled by the warden.
                </ListItem>
                <ListItem component="div">
                  <ListItemIcon className="">7</ListItemIcon> No matter your
                  status, your are not allowed to move out or in during prayer
                  sessions and Sermon period unless your child is during the
                  solemnity of the service. No walking during prayer sessions at
                  all.
                </ListItem>
                <ListItem component="div">
                  <ListItemIcon className="">8</ListItemIcon> Three elders
                  prayer in the church is reserved for those who are anointed,
                  and should progress between them in ascending order.
                </ListItem>
                <ListItem component="div">
                  <ListItemIcon className="">9</ListItemIcon> There is no
                  singing when you are asked to pray.
                </ListItem>
                <ListItem component="div">
                  <ListItemIcon className="">10</ListItemIcon> Musical
                  instruments used for the glory of God shall remain out of
                  bounds to children. No child shall be allowed to play with any
                  musical instrument.
                </ListItem>
                <ListItem component="div">
                  <ListItemIcon className="">11</ListItemIcon> There shall be
                  passable column between male and female members when
                  thanks-offering are being brought before the Lord.
                </ListItem>
                <ListItem component="div">
                  <ListItemIcon className="">12</ListItemIcon> No food except
                  water or fruit shall be taken inside the Sanctuary. No such
                  thing should happen in the altar. If you need to drink or eat
                  fruit, please do so outside of the altar of God.
                </ListItem>
                <ListItem component="div">
                  <ListItemIcon className="">13</ListItemIcon> No chewing of gum
                  under any circumstances in the Sanctuary of God.
                </ListItem>
                <ListItem component="div">
                  <ListItemIcon className="">14</ListItemIcon> No spraying or
                  pasting of money in the Sanctuary under any circumstances.
                </ListItem>
                <ListItem component="div">
                  <ListItemIcon className="">15</ListItemIcon> Use of cell
                  phones is prohibited in the Sanctuary. circumstance.
                </ListItem>
                <ListItem component="div">
                  <ListItemIcon className="">16</ListItemIcon> All jokes shall
                  strictly be reserved to the endow the Service and all
                  spiritual assignments for the day. Moreover, jokes should be
                  restrained and confined to within Christian discipline.
                </ListItem>
                <ListItem component="div">
                  <ListItemIcon className="">17</ListItemIcon> There should be
                  no talking or discussion when the Service is in progress. No
                  irrelevant conversations shall take place either in the Alter
                  or in any other part of the Sanctuary.
                </ListItem>
                <ListItem component="div">
                  <ListItemIcon className="">18</ListItemIcon> There can be no
                  interruption or interjection during the flow of Worship
                  services. The only period for interjection or interruption, if
                  it is compelling is during the time of announcement, thanks
                  offering or after the Service. This is crucial.
                </ListItem>
                <ListItem component="div">
                  <ListItemIcon className="">19</ListItemIcon> Members can be
                  allowed in, during Sermon by the warden or the preacher.
                </ListItem>
                <ListItem component="div">
                  <ListItemIcon className="">20</ListItemIcon> Tattoos are not
                  encouraged; ear-rings and weaving of hair on men are to be
                  discouraged.
                </ListItem>
                <ListItem component="div">
                  <ListItemIcon className="">21</ListItemIcon>In-fighting for
                  whatever reason is prohibited and employment of unpolished
                  words is unacceptable in the sanctuary of God.
                </ListItem>
                <ListItem component="div">
                  <ListItemIcon className="">22</ListItemIcon> Complete
                  reverence for the presence of God in the Sanctuary is a MUST
                  at all times.
                </ListItem>
              </List>
            </p>

            <br />
            <Typography variant="h5" color="primary">
              May the good Lord continue to guide and bless your household as
              you endeavor to make His Holy Sanctuary a place of reverence,
              honor and fear. He is after all, the ALMIGHTY GOD!
            </Typography>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

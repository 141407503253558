import { ContactPhone } from "@mui/icons-material";
import React, { Fragment } from "react";
import { ContactForm } from "../components/Contact/ContactForm";
import { Map } from "../components/Global/Map";
import { MiniHeader } from "../components/Global/MiniHeader";
import { BackToTop } from "../templates/BackToTop";

export const Contact = () => {
  return (
    <Fragment>
      <div className="bg-gray-50 pb-10">
        <MiniHeader
          miniTitle={<ContactPhone />}
          title="Contact"
          text="Reach out to us for spiritual counselling and prayers"
        />
      </div>

      <ContactForm />
      <Map />
      <BackToTop />
    </Fragment>
  );
};

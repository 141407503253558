import { Festival } from "@mui/icons-material";
import { Container } from "@mui/material";
import React, { Fragment } from "react";
import { MiniHeader } from "../Global/MiniHeader";
import { EventSectionCard } from "../Global/EventSectionCard";
import { format } from "date-fns";

export const EventSection = () => {
  const [events, setEvents] = React.useState([]);

  var eventUrl = `${process.env.REACT_APP_BASEURL_API}/event/all`;

  const getEvent = async () => {
    const pageURL = eventUrl;
    try {
      const response = await fetch(pageURL, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        // Always write this in order not to have CORS issues
        withCredentials: false,
        crossdomain: false,
        // mode: 'no-cors',
      });
      const jsonData = await response.json();
      setEvents(jsonData);
      // console.log(toolkits);
    } catch (err) {
      console.error(err.message);
    }
  };

  React.useEffect(() => {
    getEvent();
  }, []);

  const [noOfElement, setnoOfElement] = React.useState(1);
  const slice = events.slice(0, noOfElement);
  return (
    <Container>
      <MiniHeader
        miniTitle={<Festival />}
        title="Upcoming Event"
        text="Catch up on amazing events"
      />

      {slice.map((event, index) => (
        <Fragment key={index}>
          <EventSectionCard
            date={format(new Date(event.eventdate), "do MMM, yyyy")}
            time={format(new Date(event.eventdate), "eee h:mma")}
            title={event.title}
            text={event.body}
            path={`/events/view/${event.slug}`}
            image={event.imgSrc}
          />
        </Fragment>
      ))}
    </Container>
  );
};

import React, { Fragment } from "react";
import { MainAboutCard } from "./MainAboutCard";

export const MainAbout = () => {
  return (
    <Fragment>
      <div className="py-12">
        <MainAboutCard />
      </div>
    </Fragment>
  );
};

import "./App.css";
import { HashRouter as Router, Routes, Route } from "react-router-dom";
import { Home } from "./pages/Home";
import { About } from "./pages/About";
import { Events } from "./pages/Events";
import { Gallery } from "./pages/Gallery";
import { Contact } from "./pages/Contact";
import { PageError } from "./pages/PageError";
import { ThemeProvider } from "@mui/material";
import { customTheme } from "./utils/Themes";
import { Articles } from "./pages/Articles";
import { Header } from "./components/Global/Header";
import { Footer } from "./components/Global/Footer";
import { ViewEvent } from "./pages/subpages/ViewEvent";
import { ViewArticle } from "./pages/subpages/ViewArticle";
import { useEffect } from "react";
import { YearlyProgram } from "./pages/subpages/YearlyProgram";
import { CCChistory } from "./pages/subpages/CCChistory";
import { ParishHistory } from "./pages/subpages/ParishHistory";
import { BibleJustification } from "./pages/subpages/BibleJustification";
import { RulesTenets } from "./pages/subpages/RulesTenets";

function App() {
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  return (
    <div className="App selection:bg-custom selection:text-white">
      <ThemeProvider theme={customTheme}>
        <Router>
          <Header />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/events" element={<Events />} />
            <Route path="/events/view/:slug" element={<ViewEvent />} />
            <Route path="/events/yearly" element={<YearlyProgram />} />
            <Route path="/articles" element={<Articles />} />
            <Route path="/articles/view/:slug" element={<ViewArticle />} />
            <Route path="/gallery" element={<Gallery />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/ccc" element={<CCChistory />} />
            <Route path="/parish" element={<ParishHistory />} />
            <Route path="/justification" element={<BibleJustification />} />
            <Route path="/tenets" element={<RulesTenets />} />

            <Route path="*" element={<PageError />} />
          </Routes>
          <Footer />
        </Router>
      </ThemeProvider>
    </div>
  );
}

export default App;

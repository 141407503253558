import { LibraryBooks } from "@mui/icons-material";
import React, { Fragment } from "react";
import { MainAbout } from "../components/About/MainAbout";
import { MiniHeader } from "../components/Global/MiniHeader";
import { Member } from "../components/Home/Member";
import { BackToTop } from "../templates/BackToTop";

export const About = () => {
  return (
    <Fragment>
      <div className="bg-gray-100">
        <MiniHeader
          miniTitle={<LibraryBooks />}
          title="About"
          text="Let us give you a walk-through of this unique church."
        />
      </div>
      <MainAbout />

      <Member />
      <BackToTop />
    </Fragment>
  );
};

import { CameraRoll } from "@mui/icons-material";
import React, { Fragment } from "react";
import { GallerySection } from "../components/Gallery/GallerySection";
import { MiniHeader } from "../components/Global/MiniHeader";
import { BackToTop } from "../templates/BackToTop";

export const Gallery = () => {
  return (
    <Fragment>
      <div className="bg-gray-100 pb-10">
        <MiniHeader
          miniTitle={<CameraRoll />}
          title="Gallery"
          text="Check out our recaps from events"
        />
      </div>

      <GallerySection />
      <BackToTop />
    </Fragment>
  );
};

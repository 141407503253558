import { Container, Grid } from "@mui/material";
import React, { useState } from "react";
import Pagination from "../Global/Pagination";
// import { galleryItems } from "../../utils/GalleryData";
import GalleryModal from "./GalleryModal";

export const GallerySection = () => {
  const [galleryItems, setGalleryItems] = React.useState([]);
  var galleryViewUrl = `${process.env.REACT_APP_BASEURL_API}/gallery/all`;

  const getEvent = async () => {
    const pageURL = galleryViewUrl;
    try {
      const response = await fetch(pageURL, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        // Always write this in order not to have CORS issues
        withCredentials: false,
        crossdomain: false,
        // mode: 'no-cors',
      });
      const jsonData = await response.json();
      setGalleryItems(jsonData);
      // console.log(toolkits);
    } catch (err) {
      console.error(err.message);
    }
  };

  React.useEffect(() => {
    getEvent();
  }, []);

  const [clickedImg, setClickedImg] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(null);

  const handleClick = (item, index) => {
    setCurrentIndex(index);
    setClickedImg(item.link);
  };

  const handelRotationRight = () => {
    const totalLength = galleryItems.length;
    if (currentIndex + 1 >= totalLength) {
      setCurrentIndex(0);
      const newUrl = galleryItems[0].link;
      setClickedImg(newUrl);
      return;
    }
    const newIndex = currentIndex + 1;
    const newUrl = galleryItems.filter((item) => {
      return galleryItems.indexOf(item) === newIndex;
    });
    const newItem = newUrl[0].link;
    setClickedImg(newItem);
    setCurrentIndex(newIndex);
  };

  const handelRotationLeft = () => {
    const totalLength = galleryItems.length;
    if (currentIndex === 0) {
      setCurrentIndex(totalLength - 1);
      const newUrl = galleryItems[totalLength - 1].link;
      setClickedImg(newUrl);
      return;
    }
    const newIndex = currentIndex - 1;
    const newUrl = galleryItems.filter((item) => {
      return galleryItems.indexOf(item) === newIndex;
    });
    const newItem = newUrl[0].link;
    setClickedImg(newItem);
    setCurrentIndex(newIndex);
  };

  const [currentPage, setCurrentPage] = React.useState(1);
  const [postsPerPage, setPostsPerPage] = React.useState(6);

  const lastPostIndex = currentPage * postsPerPage;
  const firstPostIndex = lastPostIndex - postsPerPage;
  const currentPosts = galleryItems.slice(firstPostIndex, lastPostIndex);

  return (
    <React.Fragment>
      <div className="wrapper">
        <Grid container spacing={3} className="">
          {currentPosts.map((item, index) => (
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              lg={4}
              key={index}
              className="py-2 overflow-hidden"
            >
              <div className="bg-white overflow-hidden">
                <img
                  src={item.link}
                  alt={item.title}
                  onClick={() => handleClick(item, index)}
                  className="h-64 w-full object-cover drop-shadow-xl shadow-xl hover:rotate-6 duration-700 hover:scale-125 hover:duration-700"
                />
                <h2 className="font-aref text-secondary text-xl drop-shadow-xl bg-white pb-4 px-2 ">
                  {item.title}
                </h2>
              </div>
            </Grid>
          ))}
        </Grid>

        <div>
          {clickedImg && (
            <GalleryModal
              clickedImg={clickedImg}
              handelRotationRight={handelRotationRight}
              setClickedImg={setClickedImg}
              handelRotationLeft={handelRotationLeft}
            />
          )}
        </div>
      </div>
      <div className=" mx-auto">
        <Pagination
          totalPosts={galleryItems.length}
          postsPerPage={postsPerPage}
          setCurrentPage={setCurrentPage}
          currentPage={currentPage}
        />
      </div>
    </React.Fragment>
  );
};

import React from "react";
import Clock from "../../hooks/Clock";

const EventCount = ({ timeframe }) => {
  return (
    <div className="EventCount">
      <Clock deadline={timeframe} />
    </div>
  );
};

export default EventCount;

import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { mainABoutData } from "../../utils/NavData";

export const MainAboutCard = () => {
  return (
    <Fragment>
      <div className="grid grid-cols-12 py-12 justify-items-center px-4 md:px-10 lg:px-20 gap-5 relative h-full md:h-full">
        {mainABoutData.map((item) => (
          <>
            <div className="col-span-12 md:col-span-6 lg:col-span-3 h-full">
              <div className="">
                <img
                  src={item.image}
                  alt=""
                  className="h-64 md:h-64 w-full object-cover shadow-lg drop-shadow rounded-md"
                />
                <Link
                  to={item.path}
                  onClick={window.scrollTo({
                    top: 0,
                    left: 0,
                    behavior: "smooth",
                  })}
                >
                  <div className="py-4 text-2xl font-bold font-aref text-secondary">
                    {item.title}
                  </div>
                </Link>
                <p className="line-clamp-3 font-abel opacity-80">{item.text}</p>
                <Link
                  to={item.path}
                  onClick={window.scrollTo({
                    top: 0,
                    left: 0,
                    behavior: "smooth",
                  })}
                  className="text-secondary opacity-70 md:absolute bottom-0 hover:text-custom text-lg font-bold font-abel "
                >
                  Read More
                </Link>
              </div>
            </div>
          </>
        ))}
      </div>
    </Fragment>
  );
};

export const welcomeData = [
  {
    id: 1,
    text: "Spiritual Counselling",
  },
  {
    id: 2,
    text: "Consultation",
  },
  {
    id: 3,
    text: "Prayers",
  },
  {
    id: 4,
    text: "Divine healing and intervention",
  },
];
export const serviceData = [
  {
    id: 1,
    title: "Monday",
    text: "Ushers Vigil",
    time: "Starts 12:00am",
  },
  {
    id: 2,
    title: "Tuesday",
    text: "Spiritual Consultation",
    time: "12:00pm - 3:00pm",
  },
  {
    id: 3,
    title: "Wednessday",
    text: "Seekers Service",
    time: "Starts 9:00am",
  },
  {
    id: 4,
    title: "Wednessday",
    text: "Service of Mercy",
    time: "Starts 6:00pm",
  },
  //   {
  //     id: 5,
  //     title: "Thursday",
  //     text: "Ushers Vigil",
  //     time: "12:00am",
  //   },
  {
    id: 6,
    title: "Friday",
    text: "Prophet & Prophetess",
    time: "Starts 12:00pm",
  },
  {
    id: 7,
    title: "Friday",
    text: "Service for Pregnant Women",
    time: "Starts 3:00pm",
  },
  {
    id: 8,
    title: "Friday",
    text: "Service for Power",
    time: "Starts 6:00pm",
  },
  {
    id: 9,
    title: "Saturday",
    text: "Environmental Sanitation",
    time: "Starts 7:00am",
  },
  {
    id: 10,
    title: "Sunday",
    text: "Glorious Service",
    time: "Starts 10:00am",
  },
];

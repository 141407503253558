import { Festival } from "@mui/icons-material";
import React, { Fragment } from "react";
import { MiniHeader } from "../../components/Global/MiniHeader";
import { BackToTop } from "../../templates/BackToTop";
import { yearlyItems } from "../../utils/YearlyData";

export const YearlyProgram = () => {
  return (
    <Fragment>
      <div className="bg-gray-100 py-8">
        <MiniHeader
          miniTitle={<Festival />}
          title="Our Yearly Programs"
          text="Catch up on amazing events"
        />
      </div>

      <div className="overflow-x-auto relative shadow-xl sm:rounded-lg py-12 px-2 md:px-2 lg:px-20 mx-auto mb-12">
        <table className="w-full text-sm text-left px-12">
          <thead className="text-xs  uppercase bg-custom  ">
            <tr>
              <th
                scope="col"
                className="py-3 px-6 text-secondary font-aref text-2xl"
              >
                Events
              </th>
              <th
                scope="col"
                className="py-3 px-6 font-aref text-secondary text-2xl"
              >
                Period
              </th>
            </tr>
          </thead>
          {yearlyItems.map((event, index) => (
            <tbody>
              <tr className="bg-white border-b border-2 text-primary ">
                <th
                  scope="row"
                  className="py-4 px-6 capitalize font-bold text-secondary whitespace-nowrap font-abel text-xl"
                  key={index}
                >
                  {event.program}
                </th>
                <td className="py-4 px-6 font-abel text-lg capitalize">
                  {event.period}
                </td>
              </tr>
            </tbody>
          ))}
        </table>
      </div>
      <BackToTop />
    </Fragment>
  );
};

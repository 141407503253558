import React, { Fragment } from "react";
import { Link } from "react-router-dom";

export const Footer = () => {
  return (
    <Fragment>
      <footer className=" bg-secondary text-white px-4 md:px-6 ">
        <div className="lg:flex lg:justify-between pt-5">
          <div className="mb-6 md:mb-6 lg:mb-0 px-3 ">
            <Link
              onClick={() => {
                window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
              }}
              to={"/"}
              className="flex items-center justify-center lg:justify-start"
            >
              <img
                src="salv.png"
                className="md:-mr-20 lg:mr-0 h-12 w-20 md:h-12 md:w-20 lg:h-15 lg:w-24"
                alt="MH Logo"
              />
              <div className="self-center font-aref ml-2 md:ml-20 lg:ml-2 text-xl lg:text-2xl font-bold whitespace-nowrap text-white">
                Salvation Parish
              </div>
              <br />
            </Link>
            <i className="self-center flex items-center justify-center mx-auto font-aref lg:ml-2 text-sm lg:text-base font-bold whitespace-nowrap text-white">
              ...a unique church where God <br className="block md:hidden" />
              showers unique blessings
            </i>
          </div>
          <div className="grid grid-cols-12 lg:gap-10 ">
            <div className="col-span-12 md:col-span-8 lg:col-start-2 lg:col-span-6">
              <h2 className="mb-6 text-sm text-center md:text-start font-aref font-semibold text-white uppercase ">
                VISION OF THE CHURCH
              </h2>
              <div className="text-justify font-abel items-start">
                To evangelize the world through the proclamation of the Word –
                the Good News of the Messiah Jesus Christ, and of the Kingdom of
                God; in the mighty name of Jesus Christ.
              </div>
              <p className="text-justify font-abel items-start py-1">
                3, Salvation Street, Isheri Idimu, Lagos Nigeria
              </p>
              <p className="text-justify font-abel items-start ">
                +234 706 991 2863
              </p>
            </div>
            <div className="col-span-6 md:col-span-2 mx-auto">
              <h2 className="mb-6 mx-auto text-sm font-semibold text-white uppercase font-aref">
                Help
              </h2>
              <ul className="font-abel">
                <li>
                  <Link
                    className="hover-underline-animation"
                    onClick={() => {
                      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
                    }}
                    to={"/events"}
                  >
                    Events
                  </Link>
                </li>
                <li>
                  <Link
                    className="hover-underline-animation"
                    onClick={() => {
                      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
                    }}
                    to={"/articles"}
                  >
                    Articles
                  </Link>
                </li>
                <li>
                  <Link
                    className="hover-underline-animation"
                    onClick={() => {
                      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
                    }}
                    to={"/gallery"}
                  >
                    Gallery
                  </Link>
                </li>
              </ul>
            </div>
            <div className="col-span-6 md:col-span-2 mx-auto font-aref">
              <h2 className="mb-6 text-sm font-semibold text-white uppercase ">
                Other
              </h2>
              <ul className="font-abel">
                <li>
                  <Link
                    className="hover-underline-animation"
                    onClick={() => {
                      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
                    }}
                    to={"/about"}
                  >
                    About
                  </Link>
                </li>

                <li>
                  <Link
                    className="hover-underline-animation"
                    onClick={() => {
                      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
                    }}
                    to={"/contact"}
                  >
                    Contact Us
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <hr className="my-6 border-gray-200 sm:mx-auto  lg:my-8 " />
        <div className="sm:flex sm:items-center sm:justify-between pb-2 lg:px-4">
          <span className="text-sm text-white sm:text-center ">
            © 2022
            <a
              href="https://cyberbrace.com/"
              target="_blanc"
              className="ml-2 mr-8 hover:underline text-white hover:text-custom"
            >
              CyberBrace™
            </a>
            All Rights Reserved
          </span>
          <div className="flex mt-4 space-x-6 sm:justify-center sm:mt-0">
            <a
              href="https://www.facebook.com/weinsalvationparish/"
              target="_blanc"
              className="text-white hover:text-custom "
            >
              <svg
                className="w-5 h-5"
                fill="currentColor"
                viewBox="0 0 24 24"
                aria-hidden="true"
              >
                <path
                  fillRule="evenodd"
                  d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
                  clipRule="evenodd"
                ></path>
              </svg>
              <span className="sr-only">Facebook page</span>
            </a>
            <a
              href="https://www.instagram.com/cccsalvationparishlagos/"
              target="_blanc"
              className="text-white hover:text-custom "
            >
              <svg
                className="w-5 h-5"
                fill="currentColor"
                viewBox="0 0 24 24"
                aria-hidden="true"
              >
                <path
                  fillRule="evenodd"
                  d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
                  clipRule="evenodd"
                ></path>
              </svg>
              <span className="sr-only">Instagram page</span>
            </a>
          </div>
        </div>
      </footer>
    </Fragment>
  );
};

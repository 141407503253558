import {
  Box,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import React, { Fragment } from "react";
import { Check } from "@mui/icons-material";
import { welcomeData } from "../../utils/WelcomeData";

export const Welcome = () => {
  return (
    <Fragment>
      <Grid item={true} container className="">
        <Grid
          item={true}
          xs={12}
          sm={6}
          md={6}
          lg={6}
          className="hidden lg:block -mt-2"
        >
          <img
            src="https://res.cloudinary.com/dsywsrg4f/image/upload/v1664663476/salvation/bible_vunkuj.jpg"
            alt=""
            className="h-full"
          />
        </Grid>
        <Grid item={true} xs={12} sm={12} md={6} lg={6}>
          <Box className=" py-14 -mt-2 ">
            <div className="font-aref text-2xl w-3/4 mx-auto md:text-3xl text-secondary text-center font-bold">
              Welcome to CCC <span className="text-custom">Salvation</span>
              Parish
            </div>
            <div
              variant="h6"
              color="primary"
              className="!mt-10 w-3/4 md:w-[90%] lg:w-[65%] !mx-auto md:ml-12 lg:!ml-28"
            >
              CCC Salvation Parish is a a unique church where God showers unique
              blessings. This is a church in a class of its own, ordained by the
              Almighty God to provide spiritual and physical soothing balm to
              the people of the world. <br /> You can visit for:
            </div>
            <div>
              <List className="!ml-12 md:!ml-20 lg:!ml-32 ">
                {welcomeData.map((welly) => (
                  <ListItem key={welly.id}>
                    <ListItemIcon className="gap-0">
                      <Check className="bg-custom rounded-full p-1 text-white text-sm" />
                    </ListItemIcon>
                    <ListItemText primary={welly.text} />
                  </ListItem>
                ))}
              </List>
            </div>
          </Box>
        </Grid>
      </Grid>
    </Fragment>
  );
};

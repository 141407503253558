import { MenuBook } from "@mui/icons-material";
import { Grid } from "@mui/material";
import { format } from "date-fns";
import React, { Fragment } from "react";
import Pagination from "../Global/Pagination";
import { ResCard } from "../Global/ResCard";

export const ArticleCard = () => {
  const [articles, setArticles] = React.useState([]);

  var articleViewUrl = `${process.env.REACT_APP_BASEURL_API}/article/all`;

  const getEvent = async () => {
    const pageURL = articleViewUrl;
    try {
      const response = await fetch(pageURL, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        // Always write this in order not to have CORS issues
        withCredentials: false,
        crossdomain: false,
        // mode: 'no-cors',
      });
      const jsonData = await response.json();
      setArticles(jsonData);
      // console.log(toolkits);
    } catch (err) {
      console.error(err.message);
    }
  };

  React.useEffect(() => {
    getEvent();
  }, []);

  const [currentPage, setCurrentPage] = React.useState(1);
  const [postsPerPage, setPostsPerPage] = React.useState(6);

  const lastPostIndex = currentPage * postsPerPage;
  const firstPostIndex = lastPostIndex - postsPerPage;
  const currentPosts = articles.slice(firstPostIndex, lastPostIndex);

  return (
    <Fragment>
      <Grid container spacing={3} className="px-5 md:px-4 lg:px-10 py-10">
        {currentPosts.map((article, index) => (
          <Grid item={true} xs={12} sm={6} md={4} lg={4} key={index}>
            <ResCard
              image={article.imageSrc}
              title={article.title}
              btnName="Read More"
              date={format(new Date(article.createdAt), "d, MMMM yyyy")}
              path={`/articles/view/${article.slug}`}
              comHide="pr-3 font-medium text-gray-500 font-abel"
              comment={article.commentCount}
              iconText={article.author}
              icon={<MenuBook className="text-gray-400" />}
              phrase={article.phrase}
            />
          </Grid>
        ))}
      </Grid>
      <div className=" mx-auto">
        <Pagination
          totalPosts={articles.length}
          postsPerPage={postsPerPage}
          setCurrentPage={setCurrentPage}
          currentPage={currentPage}
        />
      </div>
    </Fragment>
  );
};

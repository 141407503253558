import { LibraryBooks } from "@mui/icons-material";
import React, { Fragment } from "react";
import { TenetsCard } from "../../components/About/TenetsCard";
import { MiniHeader } from "../../components/Global/MiniHeader";
import { BackToTop } from "../../templates/BackToTop";

export const RulesTenets = () => {
  return (
    <Fragment>
      <div className="bg-gray-100">
        <MiniHeader
          miniTitle={<LibraryBooks />}
          title="Rules & Tenets"
          text="“The Bible says ye shall know the truth and the truth shall make you free” John 8:32"
        />
      </div>
      <TenetsCard />
      <BackToTop />
    </Fragment>
  );
};

import { AccessTime, Festival, Stadium } from "@mui/icons-material";
import React, { Fragment } from "react";
import { MiniHeader } from "../../components/Global/MiniHeader";
import EventCount from "../../components/Events/EventCount";
import { Map } from "../../components/Global/Map";
import { BackToTop } from "../../templates/BackToTop";
import { useNavigate, useParams } from "react-router-dom";
import { format } from "date-fns";
import moment from "moment";

export const ViewEvent = () => {
  const [event, setEvent] = React.useState("");

  let navigate = useNavigate();
  const params = useParams();

  var eventUrl = `${process.env.REACT_APP_BASEURL_API}/event/view/${params.slug}`;

  const getEvent = async () => {
    const pageURL = eventUrl;
    try {
      const response = await fetch(pageURL, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        // Always write this in order not to have CORS issues
        withCredentials: false,
        crossdomain: false,
        // mode: 'no-cors',
      });
      const jsonData = await response.json();
      setEvent(jsonData);

      // console.log(toolkits);
    } catch (err) {
      console.error(err.message);
    }
  };

  React.useEffect(() => {
    getEvent();
  }, []);
  return (
    <Fragment>
      <div className="bg-gray-100 py-8">
        <MiniHeader miniTitle={<Festival />} title={event.title} text="" />

        <div className="flex justify-center ">
          <EventCount
            timeframe={moment(event.eventdate).format("MMMM,DD,YYYY")}
          />
        </div>
      </div>

      <div className="px-2 py-4 md:px-6 md:py-16 lg:px-20 lg:py-20">
        <img
          src={event.imgSrc}
          alt={event.title}
          className="w-full h-72 md:h-[600px] object-cover "
        />

        <div className="py-2">
          <div className="flex justify-start">
            <span className="flex-shrink uppercase tracking-[8px] text-xs text-gray-500 px-4 italic font-light">
              <Stadium />
            </span>

            <p className="text-center mt-1 md:mt-0 ml-1 md:-ml-1 text-xs md:text-base font-aref font-bold text-primary">
              {event.venue}
            </p>
            <span className="flex-shrink uppercase tracking-[8px] text-xs text-gray-500 px-4 italic font-light">
              <AccessTime />
            </span>

            <p className="text-center mt-1 md:mt-0 ml-1 md:-ml-1 text-xs md:text-base font-aref font-bold text-primary">
              {moment(event.eventdate).format("MMM Do, YYYY")}
            </p>
          </div>
        </div>

        <div className="py-4 font-abel text-lg">
          <div dangerouslySetInnerHTML={{ __html: event.body }} />
        </div>
      </div>
      <Map />

      <BackToTop />
    </Fragment>
  );
};

import { Send } from "@mui/icons-material";
import { Alert, Grid } from "@mui/material";
import axios from "axios";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { CustomButton } from "../../templates/CustomButton";
import { CustomInput, CustomTextArea } from "../../templates/CustomInput";

export const ContactForm = () => {
  let navigate = useNavigate();

  var mailerUrl = `${process.env.REACT_APP_BASEURL_API}/mail/sendmail`;

  const [errorMsg, setErrorMsg] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState(0);
  const [message, setMessage] = useState("");

  function validateEmail(email) {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  const submitMail = async () => {
    if (name === "") {
      setErrorMsg("Name cannot be empty");
    } else if (validateEmail(email) === false) {
      setErrorMsg("Email cannot be empty");
    } else if (phone === 0) {
      setErrorMsg("Phone cannot be empty");
    } else if (message === "") {
      setErrorMsg("Message cannot be empty");
    } else {
      const formdata = {
        name,
        email,
        phone,
        message,
      };

      await axios
        .post(mailerUrl, formdata, {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            "Access-Control-Allow-Origin": "*",
          },
          withCredentials: false,
          crossdomain: false,
          mode: "no-cors",
        })
        .then((res) => {
          setName("");
          setEmail("");
          setPhone(0);
          setMessage("");

          toast(`Mail sent successfully `, {
            type: "success",
          });
          setTimeout(() => {
            navigate(`/contact`);
          }, 6000);
        });
    }
  };
  return (
    <div className="bg-gray-50 pb-12">
      <ToastContainer />
      {errorMsg !== "" ? <Alert severity="error">{errorMsg}</Alert> : null}

      <Grid container spacing={3} className="px-5 md:px-4 lg:px-20 py-10 ">
        <Grid item xs={12} sm={4} md={4} lg={4}>
          <CustomInput
            label="Name"
            type="text"
            className="w-full h-fit bg-white"
            onChange={(e) => {
              setName(e.target.value);
              setErrorMsg("");
            }}
            value={name}
          />
        </Grid>
        <Grid item xs={12} sm={4} md={4} lg={4}>
          <CustomInput
            label="Email"
            type="email"
            className="w-full h-fit bg-white"
            onChange={(e) => {
              setEmail(e.target.value);
              setErrorMsg("");
            }}
            value={email}
          />
        </Grid>
        <Grid item xs={12} sm={4} md={4} lg={4}>
          <CustomInput
            label="Phone"
            type="number"
            className="w-full h-fit bg-white"
            onChange={(e) => {
              setPhone(e.target.value);
              setErrorMsg("");
            }}
            value={phone}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <CustomTextArea
            label="Your Message"
            rowNo={6}
            className="w-full h-fit bg-white"
            onChange={(e) => {
              setMessage(e.target.value);
              setErrorMsg("");
            }}
            value={message}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <CustomButton
            name="Submit"
            icon={<Send className="ml-2" />}
            onClick={submitMail}
          />
        </Grid>
      </Grid>
    </div>
  );
};

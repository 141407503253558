export const yearlyItems = [
  {
    program: "CCC SALVATION PARISH ANNIVERSARY - Every January 22nd",
    period: "12:00pm",
  },
  {
    program: "ADULT HARVEST - Every Third Sunday in October",
    period: "10:00am",
  },
  {
    program: "SPECIAL BLESSING SERVICE [a.k.a 12:12] - Every 12th of December",
    period: "6:00pm (Sunday: 10:00am)",
  },
  {
    program: "PALM SUNDAY",
    period: "10:00am",
  },
  {
    program:
      "PASSION WEEK - Washing Of Feet, Passover Night, Holy Communion, Good Friday",
    period: "9:00am",
  },
  {
    program: "EASTER SUNDAY",
    period: "10:00am",
  },
  {
    program: "JUVENILE HARVEST - Every First Sunday in June",
    period: "10:00am",
  },
  {
    program: "IMEKO CHRISTMAS CONVOCATION - Every 24th of December",
    period: "10:00am",
  },
];

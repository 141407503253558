export const navItems = [
  {
    name: "Home",
    slug: "home",
    path: "/",
  },
  {
    name: "About",
    slug: "about",
    path: "/about",
  },
  {
    name: "Articles",
    slug: "articles",
    path: "/articles",
  },
  {
    name: "Events",
    slug: "events",
    path: "/events",
  },
  {
    name: "Gallery",
    slug: "gallery",
    path: "/gallery",
  },
  {
    name: "Contact",
    slug: "contact",
    path: "/contact",
  },
];

export const mainABoutData = [
  {
    title: "CCC History",
    image:
      "https://res.cloudinary.com/dsywsrg4f/image/upload/v1664879358/salvation/ccc_elr9qb.jpg",
    text: "Celestial Church of Christ is a spiritual, world-wide, united, indivisible Holy Church which came into the world from heaven by DIVINE ORDER on the 29th of September 1947 in Porto Novo, Republic of Benin through the founder of the Church, the Late Reverend, Pastor, Prophet, Founder Samuel Bilehou Joseph Oshoffa. The Church is well known with Parishes, Dioceses all over the world with its International Headquarters in Nigeria.",
    path: "/ccc",
  },
  {
    title: "Parish History",
    image:
      "https://res.cloudinary.com/dsywsrg4f/image/upload/v1664879204/salvation/pulpit_xl4i0i.jpg",
    text: "Celestial Church of Christ, Salvation Parish, Isheri-Idimu, Lagos is a member of the CCC Worldwide under the pastoral leadership of Pastor Emmanuel Mobiyina Oshoffa. CCC Worldwide is a special church, in a class of its own, ordained by the Almighty God to provide spiritual and physical soothing balm to the people of the world. CCC Salvation Parish, Isheri-Idimu, Lagos",
    path: "/parish",
  },
  {
    title: "Bible Justification",
    image:
      "https://res.cloudinary.com/dsywsrg4f/image/upload/v1664879107/salvation/bible_bnigao.jpg",
    text: "Here we have the biblical justification and assurance of our tenets in Celestial Church of Christ",
    path: "/justification",
  },
  {
    title: "Rules & Tenets",
    image:
      "https://res.cloudinary.com/dsywsrg4f/image/upload/v1664879095/salvation/rules_lbjqwa.jpg",
    text: "Here is a summary of rules, regulations and tenets of Celestial Church of Christ",
    path: "/tenets",
  },
];

import { TextField } from "@mui/material";
import React from "react";

export const CustomInput = (props) => {
  return (
    <TextField
      id="outlined-basic"
      label={props.label}
      type={props.type}
      color="secondary"
      variant="outlined"
      className={props.className}
      inputProps={{ style: { fontFamily: "Abel", fontSize: 18 } }}
      onChange={props.onChange}
      value={props.value}
      required
      //   sx={{
      //     input: { fontFamily: "Goudy", fontSize: 18 },
      //     label: { fontSize: 18 },
      //   }}
    />
  );
};

export const CustomTextArea = (props) => {
  return (
    <TextField
      id="outlined-basic"
      color="secondary"
      label={props.label}
      multiline
      rows={props.rowNo}
      variant="outlined"
      className={props.className}
      inputProps={{ style: { fontFamily: "Abel", fontSize: 18 } }}
      onChange={props.onChange}
      value={props.value}
      required
      //   sx={{
      //     input: { fontFamily: "Goudy", fontSize: 18 },
      //     label: { fontSize: 18 },
      //   }}
    />
  );
};

import { MenuBook } from "@mui/icons-material";
import { Container, Grid } from "@mui/material";
import { format } from "date-fns";
import React, { Fragment } from "react";
import { MiniHeader } from "../Global/MiniHeader";
import { ResCard } from "../Global/ResCard";

export const ArticleSection = () => {
  const [articles, setArticles] = React.useState([]);

  var articleViewUrl = `${process.env.REACT_APP_BASEURL_API}/article/all`;

  const getEvent = async () => {
    const pageURL = articleViewUrl;
    try {
      const response = await fetch(pageURL, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        // Always write this in order not to have CORS issues
        withCredentials: false,
        crossdomain: false,
        // mode: 'no-cors',
      });
      const jsonData = await response.json();
      setArticles(jsonData);
      // console.log(toolkits);
    } catch (err) {
      console.error(err.message);
    }
  };

  React.useEffect(() => {
    getEvent();
  }, []);

  const [noOfElement, setnoOfElement] = React.useState(3);
  const slice = articles.slice(0, noOfElement);

  return (
    <Fragment>
      <div className="bg-gray-100 pb-10">
        <MiniHeader
          miniTitle={<MenuBook />}
          title="Articles"
          text="Understand the way of God and digest the word daily"
        />

        <Container>
          <Grid item={true} container spacing={3} className="">
            {slice.map((article, index) => (
              <Grid item={true} xs={12} sm={6} md={4} lg={4} key={index}>
                <ResCard
                  image={article.imageSrc}
                  title={article.title}
                  btnName="Read More"
                  date={format(new Date(article.createdAt), "d, MMMM yyyy")}
                  path={`/articles/view/${article.slug}`}
                  comHide="pr-3 font-medium text-gray-500 font-abel"
                  comment={article.commentCount}
                  iconText={article.author}
                  icon={<MenuBook className="text-gray-400" />}
                  phrase={article.phrase}
                />
              </Grid>
            ))}
          </Grid>
        </Container>
      </div>
    </Fragment>
  );
};

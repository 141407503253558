import React, { Fragment } from "react";
import { Box, Grid, Typography } from "@mui/material";

export const ParishHistoryCard = () => {
  return (
    <Fragment>
      <div className="px-2 py-4 md:px-6 md:py-16 lg:px-20 lg:py-20">
        <Grid container>
          <Grid
            xs={12}
            sm={4}
            md={4}
            lg={4}
            className="overflow-hidden relative"
          >
            <img
              src="https://res.cloudinary.com/dsywsrg4f/image/upload/v1667498051/salvation/federal_nhq6zo.png"
              alt=""
              className="md:hidden lg:block block w-full object-cover drop-shadow-xl shadow-xl hover:rotate-6 duration-700 hover:scale-110 hover:duration-700"
            />
            <img
              src="https://res.cloudinary.com/dsywsrg4f/image/upload/v1664998931/salvation/Oyel_oygjjg.jpg"
              alt=""
              className="hidden md:block lg:hidden w-full object-cover drop-shadow-xl shadow-xl hover:rotate-6 duration-700 hover:scale-110 hover:duration-700"
            />
          </Grid>
          <Grid xs={12} sm={8} md={8} lg={8}>
            <Box className="py-8 md:py-14 -mt-2 ">
              <div className="font-aref text-2xl w-4/4 md:w-3/4 text-center mx-auto md:ml-12 lg:!ml-16 md:text-3xl text-secondary md:text-left font-bold">
                <span className="text-custom ">Founding</span> Shepherd
              </div>
              <div className="!mt-10 w-[90%] md:w-[90%] lg:w-[65%] !mx-auto md:ml-12 lg:!ml-16">
                <p className="text-secondary font-aref -mt-4 md:mt-0 font-bold pb-2 text-xl md:text-2xl">
                  Sup.Evang. F.A Oyeleye (JP)
                  <small className="hidden md:inline-block text-sm pl-2">
                    - (1958 - 2020)
                  </small>
                  <div className="block md:hidden -mt-4 text-center text-sm  !mx-auto">
                    <br /> (1958 - 2020)
                  </div>
                </p>
                <Typography variant="h6" color="primary" className="">
                  Sup.Evang F.A Oyeleye (JP) is the Founding Shepherd of
                  Celestial Church of Christ Salvation Parish. He was called to
                  the honor in the year 1989, and served God as an epitome of
                  greatness. He moved the Church to a better place. He is a
                  father and mentor to all. Continue to rest in heart of our
                  Lord Jesus Christ.
                </Typography>
              </div>
            </Box>
          </Grid>
        </Grid>

        <div className="md:py-10">
          <div className="eventBody font-abel text-lg">
            <p className="pt-8">
              <p className="text-secondary pb-4 font-aref font-bold text-2xl md:text-3xl">
                Brief History
              </p>
              Celestial Church of Christ, Salvation Parish, Isheri-Idimu, Lagos
              is a member of the CCC Worldwide under the pastoral leadership of
              Pastor Emmanuel Mobiyina Oshoffa. CCC Worldwide is a special
              church, in a class of its own, ordained by the Almighty God to
              provide spiritual and physical soothing balm to the people of the
              world. CCC Salvation Parish, Isheri-Idimu, Lagos came into being
              on January 22, 1989 through Superior Evangelist (Prophet) Felix
              Oyeleye JP, the presiding Shepherd, a retired federal civil
              servant in the Federal Scholarship Board, where he served the
              nation meritoriously. It is important to state that our Lord Jesus
              Christ himself christened this parish as Salvation Parish. The
              original intention of the Shepherd was to name the parish,
              Bethlehem Parish, being the birthday place of our Lord Jesus
              Christ. But on the eve of the parish`s inauguration, our Lord
              Jesus Christ appeared to the Shepherd, Sup. Evangelist Oyeleye JP,
              in a dream and explained to him that though Bethlehem is His place
              of birth but He came to the world for the salvation of mankind. He
              therefore instructed him, point blank, that the name of the parish
              is Salvation and tasked him to use the parish as a springboard for
              evangelism and the salvation of souls. Hence the name-Salvation
              Parish. It would be recalled that the piece of land, where the
              parish is currently based, was originally purchased by the
              Shepherd for his personal house. Prior to the time of his calling
              (over the years, he has always been a dedicated and committed
              member of the Celestial Church of Christ right from his
              childhood), he has been fully involved in evangelism and spiritual
              activities in parishes he worshipped, particularly, Ire-Akari
              Parish, Isolo, Lagos. <br /> <br />
              The route to the commissioning of his ecclesiastic mission as a
              Shepherd was not a bed of roses. Hitherto, Superior Evangelist
              Oyeleye JP, whose is also a respected and renowned prophet, had
              received many spiritual messages that, sooner than later, he will
              “carry the cross of Jesus”, a term that means he will later in
              life be a Shepherd (Oluso). As a matter of fact, same message was
              also revealed to him by the Pastor / Founder of CCC Worldwide,
              Rev. S. B. J. Oshoffa. As it was the case in those days(the
              naiveties in particular) to be called to the vineyard of God to
              serve as a full time Shepherd was considered a terrestrial bad
              news, forgetting that apeni k`inpeni fun ya je. Today, Sup. Evang.
              Oyeleye JP has every cause to praise God, ‘for His mercies
              endureth forever’. Thirty years down the shepherdhood lane, the
              parish is growing stronger and stronger, spiritually and
              physically. In 2018, the parish celebrated its 30th Adult
              Harvest/30th Anniversary. It was indeed a manifestation of God`s
              greatness and God`s love for the parish, also a bold confirmation
              of Sup. Evang. Oyeleye`s calling. The anniversary harvest was
              indeed a spiritual and physical conviviality of the best order. As
              the Yorubas will say “ Irohin ko to afojuba”, seeing is believing
              indeed. <br /> <br />
              For spiritual succour, CCC Salvation Parish is the place to be,
              where the tenets and standard set by the Pastor/ Founder, Rev.
              S.B.J Oshoffa , are strictly adhered to. It is therefore not a
              surprise when Sup. Evang. Oyeleye JP was described as a Celestian
              to the core. Indeed, today, Sup. Evang. Oyeleye JP is one of the
              most experienced Shepherds in the celestial fold. He has been
              privileged to have had the unique opportunity to interact directly
              with the Pastor/ Founder and he learnt a lot from the Founder`s
              uncommon spiritual wisdom. Not once, in the early part of his
              sojourn in the CCC, he was in Ajase (Benin Republic) for the
              annual celestial convocation, where he received the Pastor /
              Founder`s anointment. It would be recalled that, hitherto, the
              annual celestial convocation was held in Ajase, Benin Republic.
              <br />
              <br />
              It is not an ego massage (with all humility) to describe the
              Shepherd, Sup. Evangelist Oyeleye JP, who has been a member of the
              celestial fold for over 50 years , with 30 years of this as a
              Shepherd, as indeed a Celestian to the core and a silent custodian
              of celestial church`s historical facts and developmental record.
              It is appropriate at this point to buttress this assertion with a
              fact. Over the years, Sup. Evangelist Oyeleye`s gift of prophesy
              and his unrelenting passion for God`s work in the celestial fold
              have endeared him to Baba Ajanlekoko. Many times, Baba Ajanlekoko
              had worshipped in CCC Salvation Parish.
            </p>
            <p className="pt-8">
              <p className="text-secondary pb-4 font-aref font-bold text-2xl md:text-3xl">
                Historical Facts
              </p>
              <Typography variant="h6" color="primary" className="">
                Indeed, there are two significant historical facts, unknown to
                many, that bond Baba Ajanlekoko with Sup. Evangelist Oyeleye cum
                CCC Salvation Parish.
              </Typography>
              <Typography
                variant="h5"
                color="primary"
                className="capitalize py-4"
              >
                Firstly
              </Typography>
              One particular Sunday, Baba Ajanlekoko came to worship in CCC
              Salvation Parish and when he (Sup. Evangelist Oyeleye ) saw Baba
              Ajanlekoko, he felt Baba`s visit was the usual evangelistic visit,
              but it was much more than that in the spiritual realm. As usual,
              Baba arrived about one hour to the service time. Interestingly, on
              this particular Sunday, Baba did not come alone, he came with his
              family. After, the church`s service, Baba Ajanlekoko did not
              leave, instead, he sat on a chair in the middle of the church,
              precisely just after the announcement platform and he requested
              for the microphone and he sang different celestial hymns, one
              after the other. He said the congregation can disperse, but
              anybody who wishes to join him in signing the songs is welcome.
              After some hours, all members of the congregation (one after the
              other) dispersed leaving the Shepherd (Sup. Evangelist Oyeleye)
              and members of Baba`s family that came with him. Baba Ajanlekoko
              sat on that seat singing joyfully until 11:00 pm, when he left for
              his house. Throughout and in fact for the whole day, he (Baba)
              declined the food and water Sup. Evangelist Oyeleye offered him.
              Baba Ajanlekoko later revealed to Sup. Evangelist Oyeleye the
              misery of this special visitation. Baba Ajanlekoko revealed to him
              (Sup.Evangelist Oyeleye) that God instructed him to specifically
              go to CCC Salvation Parish for his (Baba`s) last worship on earth
              (esin ida gbere f`aiye). Baba narrated to Sup Evangelist Oyeleye
              that God had told him (Baba) that his sojourn on earth is about to
              end. To this end, Baba said God instructed him to specifically go
              to CCC Salvation Parish for the Sunday service, which, according
              to Baba, will be his last service on earth. Few days later, the
              words of God came to past, Baba Ajanlekoko bid the world farewell.
              He never had the opportunity to attend any other service before
              his death.
              <Typography
                variant="h5"
                color="primary"
                className="capitalize py-4"
              >
                Secondly
              </Typography>
              Still on Baba Ajanlekoko and Sup.Evangelist Oyeleye, the Shepherd
              in charge of this parish. On a particular occasion, Baba
              Ajanlekoko specifically called Sup. Evangelist Oyeleye that he
              should come to his house in Ketu that our Lord Jesus Christ
              instructed him (Baba) to narrate to Sup. Evangelist Oyeleye all
              the history and facts of Celestial Church of Christ in Nigeria.
              This indeed is one of the numerous privileges God has given Sup.
              Evangelist Oyeleye. It will be recalled that Baba Ajanlekoko is
              the first person to join the Celestial Church of Christ in
              Nigeria. In other words, in the register of members of CCC in
              Nigeria, Baba Ajanlekoko is number one. In the course of his
              historical narration, Baba Ajanlekoko said he was Baba Oshoffa`s
              personal friend and he was instrumental to Baba Oshoffa`s
              relocation to Nigeria. Based on Baba Ajanlekoko`s resume and as
              explained by Baba himself, Baba Ajanlekoko was one of the few top
              corporate leaders in Nigeria and a power broker in the then
              private sector. According to Baba Ajanlekoko, then, there were
              just six cars in the entire Lagos and he (Baba Ajanlekoko) was
              privileged to own one of the six cars. <br />
              <br />
              On the D-day, he (Sup. Evangelist Oyeleye) went to Baba
              Ajanlekoko`s house in Ketu to keep the appointment. In view of the
              subject of the meeting, which makes it crucial and rare
              opportunity, he (Sup. Evangelist Oyeleye) decided to invite his
              close friend, Sup. Evangelist Alao, who is (and still is) the
              Shepherd of CCC Egbeda Central Parish, Lagos. He specifically
              invited him to be a living witness to the historical narration of
              Baba Ajanlekoko. When they got there, Baba Ajanlekoko was already
              seated waiting for his (Sup. Evangelist Oyeleye) arrival. When
              they got to Baba`s house, they met another very senior foundation
              member of the Celestial Church of Christ in Nigeria, Baba Leyon,
              the first Shepherd of CCC Makoko Parish, Lagos, who is reputed to
              be one of the three people that established the Makoko Parish. He
              (Baba Leyon) and the two others were fishermen from Benin
              Republic. Among the three pioneers of Makoko Parish, Baba Leyon
              was the most senior in Celestial Church rank. Baba Ajanlekoko said
              he invited Baba Leyon because he (Baba Leyon) being a foundation
              member of the church in Nigeria was privy to most of the things he
              (Baba Ajanlekoko ) was about to narrate. Baba Ajanlekoko said in
              the course of narrating the church`s history in Nigeria, Baba
              Leyon will not only collaborate his narration but whatever he
              (Baba Ajanlekoko) omits, Baba Leyon will remind him.
              <br />
              <br />
              Thereafter, Baba Ajanlekoko (with the assistance of Baba Leyon)
              went down memory lane and narrated to him (Sup. Evangelist Oyeleye
              ), in the presences of Sup. Evangelist Alao , all the history of
              Celestial Church of Christ in Nigeria. He (Sup. Evangelist
              Oyeleye) recorded the narration. It was revealing and startling.
              To this very day, most of Baba Ajanlekoko`s factual narration to
              him (Sup. Evangelist Oyeleye) are unknown to many members of
              celestial fold. Sup. Evangelist Oyeleye, through the grace of God,
              was privileged to have these rare celestial church historical
              facts. Quietly, in his “little corner”, he (Sup. Evangelist
              Oyeleye) has been busy propagating the good news of Jesus to all
              and sundry. Today, as it is commonly said in Nigerian slang
              “testimonies full ground” of God`s miracles and merciful
              activities in CCC Salvation Parish, a unique parish, where God
              showers unique blessings. Through Sup. Evangelist Oyeleye JP, God
              has healed and transformed the lives of many for good, and the
              good work of salvation continues……Please come on board.
              <br />
              <br />
            </p>
            <p className="pt-8"></p>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

import { createTheme } from "@mui/material";

export const customTheme = createTheme({
  palette: {
    primary: {
      main: "#2D2E2C",
      contrastText: "#fff",
    },
    secondary: {
      main: "#000C66",
      contrastText: "#fff",
    },
    custom: {
      main: "#FFC000",
      contrastText: "#fff",
    },
  },
  typography: {
    fontFamily: "Abel",
  },
});

import { Send } from "@mui/icons-material";
import React, { Fragment } from "react";
import { useNavigate } from "react-router-dom";
import { CustomButton } from "../../templates/CustomButton";

export const Member = () => {
  let navigate = useNavigate();
  return (
    <Fragment>
      {/* <div className="relative flex justify-center items-center w-screen h-[500px] bg-memImg object-cover bg-fixed ">
        <div className="font-aref font-bold text-white text-2xl md:text-3xl lg:text-4xl mx-auto text-center capitalize">
          Become a part of an amazing family
        </div>
        <div className="absolute bottom-[30%]">
          <CustomButton
            name="Become a Member"
            icon={<Send className="ml-2" />}
            onClick={() => navigate("/contact")}
          />
        </div>
      </div> */}

      <div className="pt-32 md:pt-32  lg:pt-40 has-bg-img  font-aref font-bold text-secondary text-2xl md:text-3xl lg:text-4xl mx-auto text-center capitalize">
        Become a part of an amazing family
        <div className="absolute left-0 right-0 pt-4">
          <CustomButton
            name="Become a Member"
            icon={<Send className="ml-2" />}
            onClick={() => {
              navigate("/contact");
              window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
            }}
          />
        </div>
      </div>
    </Fragment>
  );
};

import React, { Fragment } from "react";
import { List, ListItem, ListItemIcon, Typography } from "@mui/material";

export const CCCHistoryCard = () => {
  return (
    <Fragment>
      <div className="px-2 py-4 md:px-6 md:py-16 lg:px-20 lg:py-20">
        <img
          src="https://res.cloudinary.com/dsywsrg4f/image/upload/v1664984347/salvation/church_ne53cf.jpg"
          alt="CCC History"
          className="w-full h-72 md:h-[600px] object-cover "
        />

        <div className="py-10">
          <div className="eventBody font-abel text-lg">
            <p className="pt-8">
              <p className="text-secondary pb-4 font-aref font-bold text-2xl md:text-3xl">
                The Church
              </p>
              Celestial Church of Christ is a spiritual, world-wide, united,
              indivisible Holy Church which came into the world from heaven by
              DIVINE ORDER on the 29th of September 1947 in Porto Novo, Republic
              of Benin through the founder of the Church, the Late Reverend,
              Pastor, Prophet, Founder Samuel Bilehou Joseph Oshoffa. The Church
              is well known with Parishes, Dioceses all over the world with its
              International Headquarters in Nigeria.
            </p>
            <p className="pt-8">
              <Typography variant="h5" color="primary" className="capitalize">
                vision of the church
              </Typography>
              To evangelize the world through the proclamation of the Word – the
              Good News of the Messiah Jesus Christ, and of the Kingdom of God;
              in the mighty name of Jesus Christ, proclaiming deliverance to
              those bound by the shackles of Satan, healing the brokenhearted,
              the sick, the informed, restoring hope to the hopeless and those
              who are bound, while reinforcing the faith of all – both feeble
              and strong in the unfailing righteousness and faithfulness of God
              Almighty and Jesus Christ, the Lord & Savior.
            </p>
            <p className="pt-8">
              <Typography variant="h5" color="primary" className="capitalize">
                Primary Mission
              </Typography>
              <List className="">
                <ListItem component="div">
                  <ListItemIcon>1</ListItemIcon> To worship God Almighty in
                  holiness and reverence
                </ListItem>
                <ListItem component="div">
                  <ListItemIcon className="">2</ListItemIcon> To Preach the good
                  news of the Messiah – Jesus Christ and the imminent Kingdom of
                  God through evangelism, Bible Classes, Bible Lectures,
                  organized spiritual forums, print and electronic media.
                </ListItem>
                <ListItem component="div">
                  <ListItemIcon className="">3</ListItemIcon> To prepare
                  believers for the coming of the Lord Jesus Christ.
                </ListItem>
                <ListItem component="div">
                  <ListItemIcon className="">4</ListItemIcon> To work for the
                  unity of all Christians
                </ListItem>
                <ListItem component="div">
                  <ListItemIcon className="">5</ListItemIcon> To organize and
                  run schools, colleges, seminaries, and research centers.
                </ListItem>
                <ListItem component="div">
                  <ListItemIcon className="">6</ListItemIcon> Inculcation and
                  restoration of Christian values into home/families, to
                  encourage family cohesion, peace and stability in homes,
                  neighborhoods, and nations through prayers, counseling and
                  spiritual ministration.
                </ListItem>
                <ListItem component="div">
                  <ListItemIcon className="">7</ListItemIcon> Organize charities
                  and relief operations.
                </ListItem>
                <ListItem component="div">
                  <ListItemIcon className="">8</ListItemIcon> Promote crime free
                  and drug free environments.
                </ListItem>
              </List>
            </p>

            <p className="pt-8">
              <Typography variant="h5" color="primary" className="capitalize">
                Purpose
              </Typography>
              The purposes for which the church is organized are exclusively
              religious, charitable and educational.
            </p>

            <p className="pt-8">
              <p className="text-secondary pb-4 font-aref font-bold text-2xl md:text-3xl">
                The Divine Order
              </p>
              God called Rev., Pastor, Prophet Oshoffa on the day of total
              Eclipse of the sun in West Africa, when he was in the forest of
              Dahomey (now Republic of Benin), to purchase Ebony wood being a
              carpenter and timber trader, just like his father. He was in the
              wilderness for forty days and forty nights. Just as our Lord Jesus
              was led by the spirit into the wilderness for forty days and forty
              nights. St. Matt. 4: 1-11, Mark 1:12-13, Luke 4:1-13. Just as our
              Lord started his mission of redemption alone, so also did our
              highly esteemed Rev., Pastor, Founder, S.B.J. Oshoffa started
              alone, on the 29th September 1947.
            </p>
            <p className="pt-8">
              <p className="text-secondary pb-4 font-aref font-bold text-2xl md:text-3xl">
                The Spiritual Message
              </p>
              In the deep mystery of divine appearance, during prayer on the
              19th September 1947, a winged Angel bathed in intense light, word
              came from God to the founder as follows:
              <br />
              “It is the wish of God to send you on an errand of preaching into
              the World. Many nominal Christians there who were confronted by
              difficulties and problems of this world. They run after fetish
              priests and other power of darkness for all kinds of assistance.
              Consequently, on their death, they cannot see Christ because, by
              their action, Satan has left his spiritual mark on them. To assist
              you in in your work so that men may listen to the fact that God
              has sent you.”
            </p>
            <p className="pt-8">
              <p className="text-secondary pb-4 font-aref font-bold text-2xl md:text-3xl">
                Tenet and Mode of Worship
              </p>
              The Church under the absolute dictatorship of Holy Spirit is
              hereby emphasized for:
            </p>
            <p className="pt-8">
              <Typography variant="h5" color="primary" className="capitalize">
                The Name of the Church
              </Typography>
              The name of the Church “Celestial Church of Christ” was revealed
              by the Holy Spirit through a Prophet who was held in trance for
              seven days.
            </p>
            <p className="pt-8">
              <Typography variant="h5" color="primary" className="capitalize">
                The Tenets and Mode of Worship of the Church
              </Typography>
              Ordained by the Holy Spirit. The rules and regulations written in
              the booklet of “Tents and Admission into the membership of
              Celestial Church of Christ.”
              <br />
              Mode of worship includes the setting of the Altar, and the seat
              therein, the number of candles used for various types of services
              and the seating arrangement in particular, it should be noted that
              the seating arrangements was revealed through a Prophetess who
              under the influence of the Holy Spirit in the wilderness on Friday
              5th of October, 1947, sketched the seating arrangement using
              oranges.
            </p>
            <p className="pt-8">
              <Typography variant="h5" color="primary" className="capitalize">
                The Service of the Church
              </Typography>
              Revealed through the Holy Spirit as promised by our Lord Jesus
              Christ (John 14:25-26)
              <br />
              There is an order of service laid down in the Celestial Church of
              Christ for different occasions such as Marriage, Funeral, Naming
              Ceremony, Mass in Remembrance of the dead, etc. The order for the
              respective occasion is strictly as revealed by the Holy Spirit and
              as set out in the booklet titled “Order of Service”.
            </p>

            <p className="pt-8">
              <p className="text-secondary pb-4 font-aref font-bold text-2xl md:text-3xl">
                Holy Rites or Sacraments
              </p>
              There are a number of Holy Rites or Sacrament within the form of
              worship in Celestial Church of Christ utmost importance. Members
              shall avail themselves of the opportunity to partake in these
              rites or sacraments: Baptism, Holy Communion, Annual Washing of
              Feet, Annual Pilgrimage to Imeko, Ogun State, Nigeria at Christmas
              Eve, Annual Harvest Thanksgiving Service, and Holy Mary’s Day.
            </p>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

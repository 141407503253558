import { AccessTime, AccountBox, MenuBook, Send } from "@mui/icons-material";
import { Alert, Grid } from "@mui/material";
import axios from "axios";
import moment from "moment";
import React, { Fragment, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Commenter } from "../../components/Articles/Commenter";
import { MiniHeader } from "../../components/Global/MiniHeader";
import { BackToTop } from "../../templates/BackToTop";
import { CustomButton } from "../../templates/CustomButton";
import { CustomInput, CustomTextArea } from "../../templates/CustomInput";

export const ViewArticle = () => {
  let navigate = useNavigate();
  const params = useParams();

  const [article, setArticle] = React.useState("");
  const [articleComments, setArticleComments] = React.useState([]);

  var articleViewUrl = `${process.env.REACT_APP_BASEURL_API}/article/view/${params.slug}`;
  var commentViewUrl = `${process.env.REACT_APP_BASEURL_API}/comment/view/${article._id}`;

  const getArticle = async () => {
    const pageURL = articleViewUrl;
    try {
      const response = await fetch(pageURL, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        // Always write this in order not to have CORS issues
        withCredentials: false,
        crossdomain: false,
        // mode: 'no-cors',
      });
      const jsonData = await response.json();
      setArticle(jsonData);
      // console.log(admins);
    } catch (err) {
      console.error("err.message");
    }
  };

  const getComments = async () => {
    const pageURL = commentViewUrl;
    try {
      const response = await fetch(pageURL, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        // Always write this in order not to have CORS issues
        withCredentials: false,
        crossdomain: false,
        // mode: 'no-cors',
      });
      const jsonData = await response.json();
      setArticleComments(jsonData);

      // console.log(admins);
    } catch (err) {
      console.error("");
    }
  };

  const checkComment = () => {
    if (articleComments.length === 0) {
      getComments();
    } else {
      console.log("");
    }
  };

  React.useEffect(() => {
    getArticle();
  }, [checkComment()]);

  const [noOfElement, setnoOfElement] = React.useState(5);
  const slice = articleComments.slice(0, noOfElement);

  const loadMore = () => {
    // setnoOfElement(Users.length) //to view all at once
    setnoOfElement(articleComments.length);
  };
  const loadLess = () => {
    setnoOfElement(5);
  };

  var commentCountUrl = `${process.env.REACT_APP_BASEURL_API}/comment/update-counter/${article._id}`;

  var commentCreateUrl = `${process.env.REACT_APP_BASEURL_API}/comment/create`;

  const [errorMsg, setErrorMsg] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [comment, setComment] = useState("");

  const updateCommentCount = async () => {
    if (name === "") {
      setErrorMsg("Name cannot be empty");
    } else if (validateEmail(email) === false) {
      setErrorMsg("Email cannot be empty");
    } else if (comment === "") {
      setErrorMsg("Comment cannot be empty");
    } else {
      const formdata = {
        id: article._id,
      };

      await axios
        .put(commentCountUrl, formdata, {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          withCredentials: false,
          crossdomain: false,
          mode: "no-cors",
        })
        .then((res) => {
          console.log(res);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  function validateEmail(email) {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  const submitComment = async () => {
    if (name === "") {
      setErrorMsg("Name cannot be empty");
    } else if (validateEmail(email) === false) {
      setErrorMsg("Email cannot be empty");
    } else if (comment === "") {
      setErrorMsg("Comment cannot be empty");
    } else {
      const formdata = {
        commentName: name,
        commentEmail: email,
        commentComments: comment,
        commentArtId: article._id,
      };

      await axios
        .post(commentCreateUrl, formdata, {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          withCredentials: false,
          crossdomain: false,
          mode: "no-cors",
        })
        .then((res) => {
          setName("");
          setEmail("");
          setComment("");

          toast(`Comment added successfully `, {
            type: "success",
          });
          updateCommentCount();
          setTimeout(() => {
            window.location.reload();
          }, 6000);
        });
    }
  };

  return (
    <Fragment>
      <div className="bg-gray-100 py-8">
        <MiniHeader
          miniTitle={<MenuBook />}
          title={article.title}
          text={article.author}
        />
      </div>

      <div className="px-2 py-4 md:px-6 md:py-16 lg:px-20 lg:py-20">
        <ToastContainer />

        <img
          src={article.imageSrc}
          alt={article.title}
          className="w-full h-72 md:h-[600px] object-cover "
        />

        <div className="py-2">
          <div className="flex justify-start">
            <span className="flex-shrink uppercase tracking-[8px] text-xs text-gray-500 px-4 italic font-light">
              <AccountBox />
            </span>

            <p className="text-center mt-1 md:mt-0 ml-1 md:-ml-1 text-xs md:text-base font-aref font-bold text-primary">
              {article.author}
            </p>
            <span className="flex-shrink uppercase tracking-[8px] text-xs text-gray-500 px-4 italic font-light">
              <AccessTime />
            </span>

            <p className="text-center mt-1 md:mt-0 ml-1 md:-ml-1 text-xs md:text-base font-aref font-bold text-primary">
              {/* {format(new Date(article.createdAt), "dd, MMMM yyyy")} */}
              {/* {article.createdAt} */}
              {moment(article.createdAt).format("MMM Do, YYYY")}
            </p>
          </div>
        </div>

        <div className="py-10">
          <div className="mb-10 py-6 px-2 md:py-12  md:px-20 bg-gray-100 flex items-center gap-4 md:gap-8 relative">
            <div className="border-l-8 border-custom h-full left-0 absolute"></div>
            <div className="">
              <img
                src="https://www.pngmart.com/files/3/Quotation-PNG-Transparent-Image.png"
                alt=""
                className="w-12 h-8 ml-2 md:ml-0 md:w-16 md:h-12"
              />
            </div>
            <p className=" font-abel italic text-sm md:text-xl ">
              {article.phrase}
            </p>
          </div>
          <div className="eventBody font-abel text-lg">
            <div dangerouslySetInnerHTML={{ __html: article.body }} />
          </div>
        </div>
      </div>

      <div className="bg-gray-100 pb-12">
        <Grid container spacing={3} className="px-5 md:px-4 lg:px-20 py-10 ">
          {slice.map((comment, index) => (
            <Grid item={true} xs={12} sm={12} md={12} lg={12} key={index}>
              <Commenter
                name={comment.name}
                email={comment.email}
                time={moment(comment.createdAt).format("Do, MMM, yyyy | h:mma")}
                comment={comment.comments}
              />
            </Grid>
          ))}

          {/* <button
            className="uppercase font-montserrat text-center px-6 py-3 hover:bg-white ring-2 ring-mhblack text-mhblack"
            onClick={() => {
              if (noOfElement < articleComments.length) {
                loadMore();
              } else {
                loadLess();
              }
            }}
          >
            {noOfElement === articleComments.length ? "View Less" : "View All"}
          </button> */}

          {articleComments.length > 5 ? (
            <Grid item xs={12} sm={12} md={12} lg={12} className="text-center">
              <CustomButton
                name={
                  noOfElement === articleComments.length
                    ? "View Less"
                    : "View All"
                }
                icon={<Send className="ml-2" />}
                onClick={() => {
                  if (noOfElement < articleComments.length) {
                    loadMore();
                  } else {
                    loadLess();
                  }
                }}
              />
            </Grid>
          ) : null}
        </Grid>

        {errorMsg !== "" ? <Alert severity="error">{errorMsg}</Alert> : null}

        <Grid container spacing={3} className="px-5 md:px-4 lg:px-20 py-10 ">
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <div className=" font-aref text-3xl ">Leave a comment</div>
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <CustomInput
              label="Name"
              type="text"
              className="w-full h-fit bg-white"
              onChange={(e) => {
                setName(e.target.value);
                setErrorMsg("");
              }}
              value={name}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <CustomInput
              label="Email"
              type="email"
              className="w-full h-fit bg-white"
              onChange={(e) => {
                setEmail(e.target.value);
                setErrorMsg("");
              }}
              value={email}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={12}>
            <CustomTextArea
              label="Your Comment"
              rowNo={4}
              className="w-full h-fit bg-white"
              onChange={(e) => {
                setComment(e.target.value);
                setErrorMsg("");
              }}
              value={comment}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <CustomButton
              name="Submit"
              icon={<Send className="ml-2" />}
              onClick={submitComment}
            />
          </Grid>
        </Grid>
      </div>

      <BackToTop />
    </Fragment>
  );
};

import { LibraryBooks } from "@mui/icons-material";
import React from "react";
import { ParishHistoryCard } from "../../components/About/ParishHistoryCard";
import { MiniHeader } from "../../components/Global/MiniHeader";
import { BackToTop } from "../../templates/BackToTop";

export const ParishHistory = () => {
  return (
    <div>
      <div className="bg-gray-100 py-8">
        <MiniHeader
          miniTitle={<LibraryBooks />}
          title="CCC Salvation Parish History"
          text="Dive right in and learn about this great denomination"
        />
      </div>
      <ParishHistoryCard />
      <BackToTop />
    </div>
  );
};

import { MenuBook } from "@mui/icons-material";
import React, { Fragment } from "react";
import { ArticleCard } from "../components/Articles/ArticleCard";
import { MiniHeader } from "../components/Global/MiniHeader";
import { BackToTop } from "../templates/BackToTop";

export const Articles = () => {
  return (
    <Fragment>
      <div className="bg-gray-100 py-8">
        <MiniHeader
          miniTitle={<MenuBook />}
          title="Articles"
          text="Understand the way of God and digest the word daily"
        />
      </div>

      <ArticleCard />
      <BackToTop />
    </Fragment>
  );
};

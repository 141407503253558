import React, { Fragment } from "react";
import { ArticleSection } from "../components/Home/ArticleSection";
import { Carousel } from "../components/Home/Carousel";
import { Member } from "../components/Home/Member";
import { EventSection } from "../components/Home/EventSection";
import { Services } from "../components/Home/Services";
import { Welcome } from "../components/Home/Welcome";
import { BackToTop } from "../templates/BackToTop";

export const Home = () => {
  return (
    <Fragment>
      <Carousel />
      <Welcome />
      <Services />
      <EventSection />
      <ArticleSection />
      <Member />
      <BackToTop />
    </Fragment>
  );
};

import { Send } from "@mui/icons-material";
import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { CustomMiniBtn } from "../../templates/CustomMiniBtn";

export const EventSectionCard = (props) => {
  let navigate = useNavigate();
  return (
    <Box component="div">
      <Grid container item={true} className="overflow-hidden">
        <Grid item={true} xs={0} sm={3} md={3} lg={2} className="relative h-72">
          <div className="absolute bottom-[40%] text-center">
            <span className="text-primary text-base hidden md:block font-bold uppercase">
              {props.date}
            </span>
            <br />
            <span className="text-primary text-lg hidden md:block  font-bold opacity-60 uppercase">
              {props.time}
            </span>
          </div>
          <div className=" h-full hidden md:block py-4 shadow-none drop-shadow-none  relative">
            <div className="border-l-[1px] h-48 absolute right-8 top-16"></div>
            <div className="bg-custom w-6 text-white absolute right-5 bottom-[45%] z-10 text-base uppercase font-abel font-bold rounded-full p-[6px] ">
              <div className="rounded-full w-3 h-3 bg-white"></div>
            </div>
          </div>
        </Grid>

        <Grid
          container
          item={true}
          xs={12}
          sm={9}
          md={9}
          lg={10}
          className="my-8 shadow-lg drop-shadow h-64 overflow-hidden"
        >
          <Grid item={true} xs={12} sm={7} md={7} lg={9}>
            <Box className="md:py-8 py-4 ml-2 md:ml-12">
              <div className="font-aref text-xl mx-auto md:text-3xl text-secondary font-bold">
                {props.title}
              </div>

              <div className="inline-block md:hidden opacity-60">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-4 h-4 -mt-1 inline-block items-center"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5m-9-6h.008v.008H12v-.008zM12 15h.008v.008H12V15zm0 2.25h.008v.008H12v-.008zM9.75 15h.008v.008H9.75V15zm0 2.25h.008v.008H9.75v-.008zM7.5 15h.008v.008H7.5V15zm0 2.25h.008v.008H7.5v-.008zm6.75-4.5h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V15zm0 2.25h.008v.008h-.008v-.008zm2.25-4.5h.008v.008H16.5v-.008zm0 2.25h.008v.008H16.5V15z"
                  />
                </svg>

                <div className="inline-block text-base font-abel">
                  {props.date}
                </div>
              </div>

              <Typography
                variant="h6"
                color="primary"
                className="!mt-6 line-clamp-2 !mx-auto"
              >
                <div dangerouslySetInnerHTML={{ __html: props.text }} />
              </Typography>

              <div className="mt-2">
                <CustomMiniBtn
                  name="View Event"
                  icon={<Send className="ml-2" />}
                  onClick={() => {
                    navigate(props.path);
                    window.scrollTo({
                      top: 0,
                      left: 0,
                      behavior: "smooth",
                    });
                  }}
                />
              </div>
            </Box>
          </Grid>
          <Grid
            item={true}
            xs={0}
            sm={5}
            md={5}
            lg={3}
            className="overflow-hidden"
          >
            <img
              src={props.image}
              alt=""
              className="overflow-hidden hidden object-cover lg:block h-full"
            />
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

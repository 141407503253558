import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Slider from "react-slick";
import { CustomButton } from "../../templates/CustomButton";
import { carouselImages } from "./CarouselData";

export const Carousel = () => {
  let navigate = useNavigate();
  const [slideIndex, setSlideIndex] = useState(0);

  const settings = {
    centerMode: true,
    centerPadding: "0px",
    dots: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 2500,
    beforeChange: (current, next) => setSlideIndex(next),
    // nextArrow: <SampleNextArrow />,
    // prevArrow: <SamplePrevArrow />,
  };
  return (
    <div className="slider !w-full overflow-x-hidden -mb-2">
      <Slider {...settings}>
        {carouselImages.map((caro, index) => (
          <div key={index} className="relative">
            <img
              src={caro.img}
              alt=""
              className="object-cover drop-shadow-md !w-[500px] !h-[542px] md:!w-[1000px] md:!h-[542px] lg:!w-[1440px] lg:!h-[742px] "
              //   h-[400px] w-[500px] object-cover
            />
            <div className="absolute top-[60%] lg:top-[70%] text-center items-center left-0 right-0 ">
              <p className="font-bold font-aref text-white text-2xl lg:text-5xl mb-3">
                {caro.title}
              </p>
              <p className="font-bold font-aref italic text-white text-base lg:text-2xl mb-3 px-2 md:px-0">
                {caro.quote}
              </p>
              <CustomButton
                name={caro.label}
                //   icon={<Send className="ml-2" />}
                onClick={() => navigate(caro.path)}
              />
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

import React, { Fragment } from "react";

export const PageError = () => {
  return (
    <Fragment>
      <div className="text-center font-aref text-red-600 text-3xl font-extrabold">
        PAGE ERROR
      </div>
    </Fragment>
  );
};
